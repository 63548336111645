import React from "react"
import { Button, Switch } from "@material-ui/core"
import { globalStyles } from "../../../Componentes/CssGlobal"
import { useStyles } from "./style"
import Topo from "../../../Componentes/TopCategorias"
import { WhatsApp } from "@material-ui/icons"

export default function FalarComEspecialista() {
  const classesGlobal = globalStyles()
  const classes = useStyles()

  return (
    <div className="page-container">
      <div className="page-align" style={{ justifyContent: "flex-start" }}>
        <div>
          <Topo name="Dicas de marketing" />
        </div>
        <div className="infinite-scroll">
          <div>
            <h3>
              Quer saber como alavancar suas vendas e aproveitar ao máximo a sua loja
              online? Se a resposta for sim, clique no botão abaixo:
            </h3>
          </div>

          <div>
            <Button
              className={classes.btnServicos}
              variant="outlined"
              color="#051626"
              href="https://www.blog.uppyapp.com.br"
              target="_blank"
            >
              <a
                aria-hidden="true"
                href="https://www.blog.uppyapp.com.br"
                target="_blank"
                rel="noreferrer"
              >
                <span>Saiba mais</span>
              </a>

            </Button>

          </div>
        </div>
      </div>
    </div>
  )
}
