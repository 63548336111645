import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  Container: {
    padding: "0 1.5rem"
  },

  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },

  HeaderMain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    "& p": {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "#353535"
    }
  },

  MainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    maxWidth: "210px",
    margin: "0 auto 40px",
    "& span": {
      textAlign: "center",
      fontSize: "0.875rem",
      color: "#555555"
    }
  },

  TextArea: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",

    "& span": {
      fontSize: "0.875rem",
      color: "#555"
    },
    "& .ql-container": {
      height: "200px",
      borderRadius: "0 0 8px 8px"
    }
  },
  
  InputForm: {
    backgroundColor: "#EA3458",
    fontWeight: 500,
    width: "100%",
    maxWidth: "280px",
    borderRadius: "6px",
    color: "#fff",
    padding: "1rem 0rem",
    border: "none",
    cursor: "pointer"
  },

  FooterContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff", // Cor de fundo opcional
    borderTop: "1px solid #E4E4E4",
    padding: "1rem 0"
  },

  ErrorMessage: {
    color: "red",
    fontSize: "0.875rem",
    fontWeight: "bold",
    margin: 0
  },

  buttonSave: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",

    '&:hover': {
      boxShadow: "none !important",
      backgroundColor: "#EA3458 !important"
    }
  }
}))
