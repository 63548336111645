import { TextField } from "@material-ui/core"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { SimpleImg } from "react-simple-img"
import logo2 from "../../Assets/img/logorosa.png"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import Password from "../../Componentes/Inputs/Password"
import { button, buttonSecundary } from "../../Componentes/Inputs/TextFild"
import { validasenha, validateEmail } from "../../Componentes/validates/Validate"
import conexao from "../../Services/api"
import { useStyles } from "./style"

export default function Login() {
  const classes = useStyles()
  const history = useHistory()

  // const [alertType, setAlertType] = useState("")
  // const [showAlertOk, setShowAlertOk] = useState(false)
  // const [showAlertError, setShowAlertError] = useState(false)
  const [alertType, setAlertType] = useState("success")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [form, setForm] = useState({
    user: { value: "", error: false },
    password: { value: "", error: false }
  })

  // const closeAlerts = () => {
  //   setShowAlertOk(false)
  //   setShowAlertError(false)
  // }

  function pegadados(e) {
    const { name, value } = e

    if (name === "user") {
      setForm({
        ...form,
        [name]: { value: value.toLowerCase(), error: false }
      })
    }
    if (name === "password") {
      setForm({ ...form, [name]: { value, error: false } })
    }
  }

  function Enviar() {
    if (
      form.user.value !== "" &&
      validateEmail(form.user.value) === false &&
      form.password.value !== "" &&
      validasenha(form.password.value) === false
    ) {
      conexao
        .post("/login/pro", {
          email: form.user.value,
          password: form.password.value
        })
        .then(res => {
          localStorage.setItem("token", res.data.data.token)
          localStorage.setItem("token_cortado", res.data.data.token.substr(7))
          localStorage.setItem("email", res.data.data.email)
          localStorage.setItem("name", res.data.data.name)
          localStorage.setItem("user_id", res.data.data.user_id)
          localStorage.setItem("id", res.data.data.lojinha_id)
          localStorage.setItem("first_time", res.data.data.first_time)
          localStorage.setItem("tokenCreateAt", res.data.data.tokenCreateAt)
          localStorage.setItem("tokenExpMinutes", res.data.data.tokenExpMinutes)

          if (res.data.data.payhowIntegration) {
            localStorage.setItem(
              "payhow_token",
              res.data.data.payhowIntegration.token
            )
            localStorage.setItem(
              "payhow_id",
              res.data.data.payhowIntegration.payhow_id
            )
          }

          // setAlertType("success")
          // setShowAlertOk(true)

          setAlertMessage("Login efetuado com sucesso!")
          setAlertType("success")
          setShowModalAlert(true)

          const timer = setTimeout(() => {
            clearTimeout(timer)
            window.location.href = "/home"
          }, 1500)
        })
        .catch(error => {
          // setAlertType("error")
          // setShowAlertError(true)
          setAlertMessage("Login inválido ou usuário não é PRO!")
          setAlertType("error")
          setShowModalAlert(true)
        })
    } else {
      setForm({
        ...form,
        user: { value: form.user.value, error: validateEmail(form.user.value) },
        password: {
          value: form.password.value,
          error: validasenha(form.password.value)
        }
      })
    }
  }

  function voltar() {
    history.push("/")
  }

  const version = process.env.REACT_APP_VERSION

  return (
    <>
      <>
        <div className="page-container">
          <div className="page-align">
            <div>
              <div className={classes.positionRight}>
                <p aria-hidden="true" className={classes.version}>
                  v. {version}
                </p>
              </div>

              <div className={classes.img}>
                <SimpleImg
                  width="auto"
                  height={100}
                  alt="logo da lojinha"
                  src={logo2}
                  placeholder={false}
                />
              </div>

              <div className={classes.textfield}>
                <TextField
                  value={form.user.value}
                  onChange={e => {
                    pegadados(e.target)
                  }}
                  name="user"
                  label="E-mail"
                  variant="standard"
                  error={form.user.error}
                  helperText={form.user.error}
                  className={classes.email}
                />

                <Password
                  className={classes.senha}
                  pegadados={pegadados}
                  error={form.password.error}
                  label="Senha"
                  color="secondary"
                />
              </div>

              <div className={classes.positionRight}>
                <p
                  aria-hidden="true"
                  onClick={() => {
                    history.push("/reset")
                  }}
                  className={classes.esqueciPassword}
                >
                  Esqueci minha senha
                </p>
              </div>
              <div className={classes.widthBTN}>
                {button({ name: "Entrar", funcao: Enviar })}
                {buttonSecundary({ name: "Voltar", funcao: voltar })}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          {/* <CustomAlert
            type={alertType}
            alertText={
              alertType === "success"
                ? "Login efetuado com sucesso!"
                : "Login inválido!"
            }
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          /> */}

          {showModalAlert ? (
            <CustomAlert
              type={alertType}
              alertText={alertMessage}
              show={showModalAlert}
              close={() => setShowModalAlert(false)}
            />
          ) : (
            ""
          )}
        </div>
      </>
    </>
  )
}
