import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import WarningRoundedIcon from "@material-ui/icons/WarningRounded"
import React, { useLayoutEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import BannerSejaPro from "../../Componentes/BannerSejaPro"
import { globalStyles } from "../../Componentes/CssGlobal"
import PagSeguroModal from "../../Componentes/PagSeguroModal"
import TopCategorias from "../../Componentes/TopCategorias"
import { useSettings } from "../../ContextAPI/settingsContext"
import api from "../../Services/api"
import { useStyles } from "./style"
import { CircularProgress } from "@material-ui/core"

export default function MenuMeiosDePagamento() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const history = useHistory()
  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2
  const [paymentActive, setPaymentActive] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const shopId = localStorage.getItem("id")
  const [showAlert, setShowAlert] = useState(false)
  const [integrationActive, setIntegrationActive] = useState(null)
  const [loading, setLoading] = useState(false)
  const [warning, setWarning] = useState(false)
  const [dimensionInfo, setDimensionInfo] = useState()

  async function getDataPayment() {
    setLoading(true)
    const payment = await api.get(`lojinha/payment/get/${shopId}`)
    setPaymentActive(payment.data.data)

    const { data } = await api.get(`payhow/getIntegrationStatus/${shopId}`)
    const integrationStatus = data.data

    const dimensionData = await api.get(`product/check/dimesion/${shopId}`)
    setDimensionInfo(dimensionData.data.data)

    if (integrationStatus === true) {
      setIntegrationActive(true)
    } else {
      setIntegrationActive(false)
    }
    setLoading(false)
  }

  function openModalPayhow() {
    setOpenModal(true)
  }

  function handleCloseModal() {
    setOpenModal(false)
  }

  // const handlePayhowCheckout = async () => {
  //   if (loading) return
  //   const { data } = await api.get(`product/check/dimesion/${shopId}`)
  //   let allProductsHasDimension = data.data
  //   if (integrationActive) {
  //     if (allProductsHasDimension) {
  //       history.push("/checkout-menu")
  //     } else {
  //       setShowAlert(true)
  //     }
  //   } else {
  //     openModalPayhow()
  //   }
  // }

  const handlePayhowCheckout = async () => {
    if (loading) return
    if (integrationActive) {
      if (dimensionInfo === true) {
        history.push("/checkout-menu")
      } else {
        setLoading(true)
        setShowAlert(true)
        setTimeout(() => {
          history.push("/checkout-menu")
        }, 3000)
      }
    } else {
      openModalPayhow()
    }
  }

  useLayoutEffect(() => {
    getDataPayment()
  }, [])

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <TopCategorias name="Meios de Pagamento" />
          </div>
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <div
                  className={classes.Box}
                  aria-hidden="true"
                  onClick={() => {
                    history.push("/negociacao-via-whatsapp")
                  }}
                >
                  <div className={classesGlobal.fonteTitulo}>
                    Negociação manual via Whatsapp
                  </div>
                  <div className={classes.btnalinhamento}>
                    <div className={classesGlobal.fonteSubtitulo}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        Nessa opção todos os pedidos são enviados diretamente para
                        seu Whatsapp e não existe um pagamento automático através de
                        um checkout
                        {paymentActive.payment_active === "manual" ? (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CheckCircleIcon
                              style={{ color: "#27AE60", fontSize: "1rem" }}
                            />
                            <p
                              style={{
                                color: "#27AE60",
                                margin: "5px 0px",
                                fontSize: "1rem"
                              }}
                            >
                              ativo
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div>
                      <ArrowForwardIosIcon fontSize="small" color="disabled" />
                    </div>
                  </div>
                  <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                </div>

                <div
                  className={classes.Box}
                  aria-hidden="true"
                  onClick={handlePayhowCheckout}
                >
                  <div className={classesGlobal.fonteTitulo}>
                    Checkout de pagamento
                    <button className={classes.btnNews}>novidade</button>
                  </div>
                  <div className={classes.btnalinhamento}>
                    <div>
                      <div className={classesGlobal.fonteSubtitulo}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          Receba seus pagamentos automaticamente via cartão de
                          crédito, débito, boleto bancário e PIX utilizando o nosso
                          super checkout
                          {paymentActive.payment_data &&
                          paymentActive.payment_data.payhow ? (
                            paymentActive.payment_data.payhow.pagseguro === 1 ||
                            paymentActive.payment_data.payhow.mercadopago === 1 ? (
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <CheckCircleIcon
                                  style={{ color: "#27AE60", fontSize: "1rem" }}
                                />
                                <p
                                  style={{
                                    color: "#27AE60",
                                    margin: "5px",
                                    fontSize: "1rem"
                                  }}
                                >
                                  ativo
                                </p>
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {dimensionInfo === false &&
                          !(
                            paymentActive.payment_data &&
                            paymentActive.payment_data.payhow &&
                            (paymentActive.payment_data.payhow.pagseguro === 1 ||
                              paymentActive.payment_data.payhow.mercadopago === 1)
                          ) ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <WarningRoundedIcon
                                style={{ color: "#ff9800", fontSize: "1rem" }}
                              />
                              <p
                                style={{
                                  color: "#ff9800",
                                  margin: "5px",
                                  fontSize: "0.9rem"
                                }}
                              >
                                Existem produtos sem dimensões definidas
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <ArrowForwardIosIcon fontSize="small" color="disabled" />
                    </div>
                  </div>
                  <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <CustomAlert
        type="warning"
        alertText="Para ativar a integração com os correios ou melhor envio, seus produtos precisam ter as dimensões configuradas."
        show={showAlert}
        close={() => setShowAlert(false)}
      />
      {openModal === true ? (
        <PagSeguroModal isOpen={openModal} handleClose={handleCloseModal} />
      ) : (
        ""
      )}
    </>
  )
}
