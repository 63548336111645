import React, { useState } from "react"
import { useSettings } from "../../../../ContextAPI/settingsContext"
import { useProduct } from "../../../../ContextAPI/productContext"
import Cropper from "../../../../Componentes/Cropper"
import conexao from "../../../../Services/api"
import { readFile } from "../../../../utils"

import addPictureIcon from "../../../../Assets/svg/addPictureIcon.svg"
import PlanosAlertaModal from "../../../Planos/Alertas/AlertaModal"

import { useStyles } from "./style"
import ModalFoto from "./ModalFoto"
import FotosContainer from "./DragAndDropFotos/FotosContainer"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export default function ProdutoFotos() {
  const classes = useStyles()
  const history = useHistory()
  const { lojinhaInfo } = useSettings()
  const { currentProduct, setCurrentProduct } = useProduct()
  const [imageSrc, setImageSrc] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
  const [openModalPhoto, setOpenModalPhoto] = useState(false)
  const [fullImage, setFullImage] = useState({ url: "", index: "", order: null })
  const [openModal, setOpenModal] = useState(false)

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  return (
    <>
      <h2>Fotos do produto</h2>
      <p>
        Aqui você pode definir as fotos do produto e decidir qual será a foto de
        capa.
      </p>
      <p style={{ fontWeight: 500, color: "#555555" }}>
        Adicione até 5 fotos do seu produto:
      </p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr",
          justifyItems: "center",
          marginBottom: "30px"
        }}
      >
        <div className={classes.positionFotos}>
          <FotosContainer />
        </div>

        <div></div>
      </div>
      <p style={{ fontWeight: 200, color: "#555555", textAlign: "center" }}>
        (arraste as fotos para organizar a ordem de exibição)
      </p>
    </>
  )
}
