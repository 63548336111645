import { Box, Switch } from "@material-ui/core"
import Badge from "@material-ui/core/Badge"
import CloseIcon from "@material-ui/icons/Close"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone"
import React, { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { Link, useHistory } from "react-router-dom"
import { SimpleImg } from "react-simple-img"
import Menu from "../../Assets/Home/menu.svg"
import logoBanner from "../../Assets/img/logoBanner.png"
import logoBranco from "../../Assets/img/logobranco.png"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { useDevice } from "../../ContextAPI/deviceContext"
import { useProduct } from "../../ContextAPI/productContext"
import { useSettings } from "../../ContextAPI/settingsContext"
import api from "../../Services/api"
import { logout } from "../../utils"
import HomeBottom from "./components/HomeBottom"
import LojinhaHeader from "./components/LojinhaHeader"
import LojinhaInformations from "./components/LojinhaInformations"
import LojinhaWantedProducts from "./components/LojinhaWantedProducts"
import Wave from "./components/WaveSvg"
import { useStyles } from "./style"
import complementsModoManutencao from "../../Services/api/modomanutencao"

export default function Home() {
  const [cookies, setCookie] = useCookies(["userInfo"])
  const classes = useStyles()
  const history = useHistory()
  const { checkDevice, isMobileDevice } = useDevice()
  const { setMostWantedProducts } = useProduct()
  const { progressCount } = useSettings()
  const shopId = localStorage.getItem("id")
  const userId = localStorage.getItem("user_id")
  const [sales, setSales] = useState([])
  const [orders, setOrders] = useState()
  const [totalOrders, setTotalOrders] = useState()
  const [shopInformations, setShopInformations] = useState()
  const [loading, setLoading] = useState(true)
  const [wantedProducts, setWantedProducts] = useState([])
  const [hideWave, setHideWave] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [showAlert, setShowAlert] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [enableInputs, setEnableInputs] = useState(false)

  async function getPlaceHolders() {
    const { data } = await complementsModoManutencao.get()
    setEnableInputs(data.data.complements_placeholders_status)
  }
  function sendData() {
    try {
      complementsModoManutencao.put({
        complements_placeholders_status: !enableInputs
      })
      setEnableInputs(!enableInputs)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getPlaceHolders()
  }, [])

  const closeAlerts = () => {
    setShowAlert(false)
  }

  useEffect(() => {
    if (localStorage.getItem("tokenCreateAt") === null) {
      logout()
    }
    if (
      localStorage.getItem("first_time") === "1" ||
      localStorage.getItem("first_time") === null
    ) {
      handleSessaoGuiada()
    } else {
      getShopData()
    }
  }, [])

  const handleSessaoGuiada = async () => {
    history.push("/primeiro-acesso")
  }

  async function getShopData() {
    getShopInformations()
    getMostWantedProducts()
    getOrderInformations()
    getShopTotalSales()
    getOrderTotalInformations()
  }
  /* PRODUTOS PENDENTES SININHO */
  async function getOrderInformations() {
    const dataOrder = await api.get(`/notification/count/${userId}`)
    setOrders(dataOrder.data.data)
  }
  /* TRACKING TOTAL */
  async function getShopInformations() {
    const data = await api.get(`tracking/total/${shopId}`)

    setShopInformations(data.data.data.total_visitors)
  }
  /* GET TOTAL SALES */
  async function getShopTotalSales() {
    const data = await api.get(`/product/gettotalpricebyyear/${shopId}/10`)
    const newData = data.data.data

    let priceYearTotal = 0

    for (let index = 0; index < newData.length; index++) {
      priceYearTotal = newData[index].total_price + priceYearTotal
    }

    setSales(priceYearTotal)
  }
  /* ORDER TOTAL INFO */
  async function getOrderTotalInformations() {
    const dataOrder = await api.get(`/order/status/${shopId}`)
    const newDataOrders = dataOrder.data.data

    let OrderTotal = 0

    for (let index = 0; index < newDataOrders.length; index++) {
      OrderTotal = newDataOrders[index].total + OrderTotal
    }

    setTotalOrders(OrderTotal)
    setLoading(false)
  }
  /* PRODUTOS EM DESTAQUE */
  async function getMostWantedProducts() {
    const { data } = await api.get(`tracking/getproducts/${shopId}/5`)
    setMostWantedProducts([...data.data])
    setWantedProducts([...data.data])
    setLoading(false)
  }
  /* SCROLL ANIMATION */
  function verifyScroll() {
    if (document.getElementById("Home")) {
      if (document.getElementById("Home").scrollTop > 100) {
        setHideWave(true)
      } else if (document.getElementById("Home").scrollTop <= 100) {
        setHideWave(false)
      }
    }
  }
  /* ALERTA */
  function alertLink() {
    setAlertType("success")
    setShowAlert(true)
  }

  useEffect(() => {
    if (cookies["user_id"] === undefined) {
      let userID = localStorage.getItem("user_id")
      setCookie("user_id", userID)
    }

    window.addEventListener("scroll", verifyScroll, true)
    return window.removeEventListener("scroll", verifyScroll)
  }, [])

  return (
    <>
      <div
        className="page-container"
        style={{
          height: "100%"
        }}
      >
        <div hidden={hideWave}>
          <Wave />
        </div>
        <div className="page-align" style={{ padding: "0" }}>
          <div
            className="headerFixed"
            style={{
              backgroundColor: "#EA3458",
              width: "100%",
              padding: "0 20px",
              boxSizing: "border-box",
              borderRadius: "0px 0px 15px 15px"
            }}
          >
            <div
              className={classes.bodyHeader}
              style={{
                padding: "0 20px 10px"
              }}
            >
              <div
                aria-hidden="true"
                onClick={() => {
                  history.push("/home/left-menu")
                }}
                className={classes.menuIcon}
              >
                <SimpleImg
                  src={Menu}
                  alt="Menu Icon"
                  width={20}
                  height="auto"
                  placeholder={false}
                />
              </div>
              <div className={classes.logoBranco}>
                <SimpleImg
                  src={logoBranco}
                  alt="Lojinha Icon"
                  width={80}
                  height={50}
                  placeholder={false}
                />
              </div>
              <Badge
                badgeContent={orders}
                color="primary"
                onClick={() => history.push("/notificacoes")}
                style={{ cursor: "pointer" }}
              >
                <NotificationsNoneIcon
                  style={{ color: "#fff", fontSize: "1.9rem" }}
                />
              </Badge>
            </div>
          </div>
          <div id="Home" className="infinite-scroll" style={{ padding: "0 20px" }}>
            {progressCount < 5 && (
              <Link to="/progresso-de-configuracoes">
                <Box alignItems="center" display="flex" justifyContent="center">
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "1.1rem",
                      fontWeight: "600",
                      margin: "0",
                      marginRight: "2px"
                    }}
                  >
                    <span
                      style={{
                        color: "#0D273D",
                        textDecoration: "underline",
                        textDecorationThickness: "1px",
                        marginRight: "2px"
                      }}
                    >
                      {progressCount} DE 5 ETAPAS{" "}
                    </span>
                    CONCLUÍDAS
                  </p>
                  <ExpandMoreIcon style={{ color: "#fff", fontSize: "1.8rem" }} />
                </Box>
              </Link>
            )}

            <LojinhaHeader loading={loading} alertLink={alertLink} />
            <>
              {enableInputs ? (
                <div className={classes.ContainerMaintence}>
                  <h2>Modo Demonstração</h2>
                  <p>
                    O modo demonstração vem ativado ao criar a loja, para que você
                    possa visualizar como será a sua loja com todas as opções
                    configuradas corretamente. Ao desativar o modo manutenção, sua
                    loja não irá mais mostrar as prévias dos campos que podem ser
                    personalizados. Caso queira ativar novamente o modo demonstração,
                    é necessário entrar em Personalizar Loja {">"} Modo demonstração.
                  </p>
                  <div>
                    <label>Ativar</label>
                    <Switch
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={() => {
                        setEnableInputs(!enableInputs)
                        sendData()
                      }}
                      checked={enableInputs}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <LojinhaInformations
                    loading={loading}
                    shopInformations={shopInformations}
                    sales={sales}
                    totalOrders={totalOrders}
                  />
                  <div>
                    <h1
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        marginTop: "25px"
                      }}
                    >
                      Produtos mais procurados:
                    </h1>
                    <LojinhaWantedProducts
                      products={wantedProducts}
                      loading={loading}
                    />
                  </div>
                </>
              )}
            </>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <CustomAlert
              type={alertType}
              alertText="Link copiado com sucesso"
              show={showAlert}
              close={() => closeAlerts()}
            />
          </div>

          <div className="footerFixed">
            <hr style={{ width: "100%", borderColor: "#E4E4E4", opacity: 0.3 }} />
            <HomeBottom />
          </div>
        </div>
      </div>
    </>
  )
}
