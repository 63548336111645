import Avatar from "@material-ui/core/Avatar"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import Skeleton from "@material-ui/lab/Skeleton"
import { SimpleImg } from "react-simple-img"
import btnFacebook from "../../../../Assets/Home/btnFacebook.svg"
import btnTwitter from "../../../../Assets/Home/btnTwitter.svg"
import coroinha from "../../../../Assets/Home/coroinha.png"
import groupFiles from "../../../../Assets/Home/groupFiles.svg"
import { useSettings } from "../../../../ContextAPI/settingsContext"
import { useStyles } from "./style"

export default function LojinhaHeader({ loading, alertLink }) {
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()

  function copyText() {
    const element = `https://${lojinhaInfo.url}.uppy.app`

    navigator.clipboard.writeText(element)

    alertLink()
  }

  return (
    <>
      {loading ? (
        <div className={classes.headerContainer}>
          <section className={classes.headerContent}>
            <Skeleton variant="circle" width={66.8} height={66.8} />

            <div style={{ width: "60%" }} className={classes.headerInfos}>
              <Skeleton width="100%" />
              <Skeleton width="80%" />
            </div>
          </section>

          <section className={classes.headerButtons}>
            <Skeleton width="100%" height="40px" />
          </section>

          <section className={classes.headerMedias}>
            <Skeleton width="100%" height="20px" />
          </section>
        </div>
      ) : (
        <div className={classes.headerContainer}>
          <section
            style={{ marginTop: lojinhaInfo.plan_id === 2 ? "14px" : "" }}
            className={classes.headerContent}
          >
            {lojinhaInfo.plan_id === 2 ? (
              <>
                <SimpleImg
                  width={75}
                  height="auto"
                  placeholder={false}
                  className={classes.coroinha}
                  src={coroinha}
                  alt="Borda Coroinha"
                />
                <Avatar
                  alt="Remy Sharp"
                  src={lojinhaInfo.sm_url}
                  className={classes.large}
                />
              </>
            ) : (
              <div className={classes.avatarContent}>
                <Avatar
                  alt="Remy Sharp"
                  src={lojinhaInfo.sm_url}
                  className={classes.large}
                />
                <span>gratuito</span>
              </div>
            )}
            <div className={classes.headerInfos}>
              <p className={classes.headerName}>{lojinhaInfo.name}</p>
              <p className={classes.headerSite} style={{ lineBreak: "anywhere" }}>
                https://{lojinhaInfo.url}.uppy.app
              </p>
            </div>
          </section>

          <section className={classes.headerButtons}>
            <button className={classes.button} type="button">
              <a
                id="copy"
                href={
                  lojinhaInfo.url
                    ? `https://${lojinhaInfo.url}.uppy.app`
                    : `https://${localStorage
                        .getItem("name")
                        .toLowerCase()}.uppy.app`
                }
                target="_blank"
                rel="noreferrer"
              >
                <span>Visitar</span>
              </a>
            </button>
            <button
              className={classes.button}
              style={{ marginLeft: "10px", cursor: "copy" }}
              type="button"
              onClick={copyText}
            >
              <span>
                Copiar link
                <SimpleImg
                  width="auto"
                  height="auto"
                  placeholder={false}
                  src={groupFiles}
                  style={{ marginLeft: "6.54px" }}
                  alt="img"
                />
              </span>
            </button>
          </section>

          <section className={classes.headerMedias}>
            <span>Compartilhar: </span>
            <div>
              <a
                href={`https://www.facebook.com/sharer.php?u=https://${lojinhaInfo.url}.uppy.app`}
                target="_blank"
                rel="noreferrer"
              >
                <SimpleImg
                  width={20}
                  height="auto"
                  placeholder={false}
                  src={btnFacebook}
                  style={{ marginLeft: "13px" }}
                  alt="Btn Facebook"
                />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=https://${lojinhaInfo.url}.uppy.app`}
                target="_blank"
                rel="noreferrer"
              >
                <SimpleImg
                  width={20}
                  height="auto"
                  placeholder={false}
                  src={btnTwitter}
                  style={{ marginLeft: "9.5px" }}
                  alt="Btn Twitter"
                />
              </a>

              <a
                href={`https://wa.me/?text=https://${lojinhaInfo.url}.uppy.app`}
                target="_blank"
                data-action="share/whatsapp/share"
                rel="noreferrer"
              >
                <WhatsAppIcon
                  style={{
                    color: "#fff",
                    backgroundColor: "#3DC34F",
                    padding: "5px",
                    fontSize: "0.75rem",
                    borderRadius: "50px",
                    marginLeft: "9.5px"
                  }}
                />
              </a>
            </div>
          </section>
        </div>
      )}
    </>
  )
}
