import React from "react"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useHistory } from "react-router-dom"
import { useStyles } from "./style"
import Topo from "../../Componentes/TopCategorias"
import { globalStyles } from "../../Componentes/CssGlobal"
import { useSettings } from "../../ContextAPI/settingsContext"

export default function Configuracoes() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const history = useHistory()
  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
          <Topo rota={"home/left-menu"} name="Ajustes" />
          </div>
          <div className="infinite-scroll">
            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/personalizar-lojinha")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Personalize sua loja</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Personalize sua loja virtual com as cores, logo e informações de
                  contato
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/menu-opcoes-de-entrega")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>
                Opções de entrega
                <button className={classes.btnNews}>novidade</button>
              </div>
              <div className={classes.btnalinhamento}>
                <div>
                  <div className={classesGlobal.fonteSubtitulo}>
                    Frete grátis, frete fixo, retirada no local, entre outros
                  </div>
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/menu-meios-de-pagamento")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>
                Meios de Pagamento
                <button className={classes.btnNews}>novidade</button>
              </div>
              <div className={classes.btnalinhamento}>
                <div>
                  <div className={classesGlobal.fonteSubtitulo}>
                    Adicione em sua loja virtual os métodos de pagamento que você
                    aceita
                  </div>
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            
          </div>
        </div>
      </div>
    </>
  )
}
