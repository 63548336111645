import { CircularProgress } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import TextField from '@material-ui/core/TextField';
import { useEffect, useState } from "react"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { button } from "../../../Componentes/Inputs/TextFild"
import { useProduct } from "../../../ContextAPI/productContext"
import storiesRequest from "../../../Services/api/stories"
import ProductsList from "../../ProdutosStories/Components/ProductsList"
import DescriptionIcon from "@material-ui/icons/Description"
import { useStyles } from "./style"
export default function EtapaStoriesProduto({ categoryId, handleStep, onSetStep }) {
  const [errorProductExist, setErrorProductExist] = useState(false)
  const [isCreatingStory, setIsCreatingStory] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [isProductsEmpty, setIsProductsEmpty] = useState(false)
  const { storiesProductsFiltredByCategory, setStoriesProductsFiltredByCategory } =
    useProduct()
  const classes = useStyles ()
  const getOptions = async () => {
    try {
      setIsLoading(true)
      const { data } = await storiesRequest.filterProducts(categoryId)
      setOptions(data.data)
      setIsProductsEmpty(data.data.length === 0)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getOptions()
    setStoriesProductsFiltredByCategory([])
  }, [])
  const handleCreateStories = async () => {
    const body = {
      category_id: categoryId,
      story_products: storiesProductsFiltredByCategory
    }
    try {
      setIsCreatingStory(true)
      await storiesRequest.createStory(body)
      setShowAlert(true)
      setAlertType("success")
      handleStep("proximo")
    } catch (err) {
      setShowAlert(true)
      setAlertType("error")
    } finally {
      setIsCreatingStory(false)
    }
  }
  return (
    <>
      <div className="infinite-scroll">
        <div className="EtapaHeader">
          <h2>Conheça nossa funcionalidade de Stories</h2>
          <h3>Agora selecione os produtos:</h3>
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              minHeight: "100%",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "40px"
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            {isProductsEmpty ? (
              <>
                <div className={classes.EmptyProducts}>
                  <p>Não há produtos cadastrados!<a className="voltarLink" onClick={() => onSetStep(5)}>Cadastre aqui!</a></p>
                  <DescriptionIcon />
                </div>
              </>
            ) : (
              <div className="infinite-scroll">
                <div style={{ marginBottom: "1rem" }}>
                  <Autocomplete
                    id="tags-standard"
                    onChange={(event, newValue) => {
                      const isProductExist = storiesProductsFiltredByCategory.some(
                        existingProduct =>
                          existingProduct.product_id === newValue?.product_id
                      )
                      if (isProductExist) {
                        setErrorProductExist(true)
                        return
                      }
                      if (newValue && !isProductExist) {
                        setStoriesProductsFiltredByCategory(prevList => [
                          ...prevList,
                          newValue
                        ])
                        setErrorProductExist(false)
                        return
                      }
                    }}
                    options={options}
                    getOptionLabel={option => option.product_title}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Produtos"
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={props.product_image}
                            alt={props.product_title}
                            style={{ width: 50, marginRight: 10 }}
                          />
                          {props.product_title}
                        </div>
                      </li>
                    )}
                  />
                  <h3>Organize abaixo a ordem de exibição:</h3>
                  {errorProductExist && (
                    <p style={errorStyle}>Esse produto já está selecionado.</p>
                  )}
                </div>
                <div className="infinite-scroll">
                  <ProductsList />
                </div>
              </div>
            )}
          </>

        )}
        {showAlert ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            {/* alertas */}
            <CustomAlert
              alertText={
                alertType === "success"
                  ? "Informações salvas com sucesso!"
                  : "Prencha um número para contato!"
              }
              show={showAlert}
              type={alertType}
              close={() => closeAlert()}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="footer-column">
        {button({
          funcao: () => handleCreateStories(),
          name: `Próximo`
        })}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <button
            onClick={() => handleStep("backStep")}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Voltar etapa
          </button>
          <button
            onClick={() => handleStep("nextStep")}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Pular etapa
          </button>
        </div>
      </div>
    </>
  )
}
