import React from "react"
import { Route, Switch } from "react-router-dom"
import InMaintenance from "./Componentes/InMaintenance"
import PrivateRoute from "./Componentes/PrivateRoute"
import Atendimento from "./Pages/Atendimento"
import AtivarWhatsapp from "./Pages/AtivarWhatsapp"
import AddBanner from "./Pages/Banner/AddBanner"
import Banner from "./Pages/Banner/Main"
import SecondaryBanners from "./Pages/Banner/Secondary"
import BemVindo from "./Pages/BemVindo"
import Categoria from "./Pages/Categoria"
import NovaCategoria from "./Pages/Categoria/Componentes/AddCategorias"
import CategoriasNova from "./Pages/CategoriasNova"
import Subcategorias from "./Pages/CategoriasNova/Subcategorias"
import StoryCategories from "./Pages/CategoriasStories"
import Configuracoes from "./Pages/Configuracoes"
import ActivePayhowRegistration from "./Pages/Configuracoes/ActivePayhowRegistration"
import ActiveSuperCheckout from "./Pages/Configuracoes/ActiveSuperCheckout"
import Checkout from "./Pages/Configuracoes/Checkout"
import CheckoutPayhow from "./Pages/Configuracoes/CheckoutPayhow"
import ServiceAndRegistrationStatus from "./Pages/Configuracoes/CheckoutPayhow/ServiceAndRegistrationStatus"
import DuvidasFrequentes from "./Pages/DuvidasFrequentes"
import Estatisticas from "./Pages/Estatisticas"
import AcompanhamentoVendas from "./Pages/Estatisticas/AcompanhamentoVendas"
import RelatorioVisitas from "./Pages/Estatisticas/RelatorioVisitas"
import FooterStoreInfo from "./Pages/FooterStoreInfo"
import FrasesDestaques from "./Pages/FrasesDestaque"
import GerenciaProdutos from "./Pages/GerenciaProdutos"
import Home from "./Pages/Home"
import LeftMenu from "./Pages/Home/components/LeftMenu"
import InformacoesDaLojinha from "./Pages/InformacoesDaLojinha"
import InstitutionalInfo from "./Pages/InstitutionalInfo"
import MeusProdutos from "./Pages/ListagemProduto"
import Login from "./Pages/Login"
import MaisVendidos from "./Pages/MaisVendidos"
import Marketing from "./Pages/Marketing"
import ConfigSEO from "./Pages/Marketing/ConfigSEO"
import FaceInsta from "./Pages/Marketing/FaceInsta"
import FacebookPixel from "./Pages/Marketing/FaceInsta/FacebookPixel"
import InstagramShopping from "./Pages/Marketing/FaceInsta/InstagramShopping"
import VerificacaoDominio from "./Pages/Marketing/FaceInsta/VerificacaoDominio"
import FalarComEspecialista from "./Pages/Marketing/FalarComEspecialista"
import GoogleAnalytics from "./Pages/Marketing/GoogleAnalytics"
import MeiosDePagamentos from "./Pages/MeiosDePagamentos"
import CheckoutPagseguro from "./Pages/MeiosDePagamentos/components/CheckoutPagseguro"
import FeesAndTariffs from "./Pages/MeiosDePagamentos/components/Payhow/FeesAndTariffs"
import PayhowConfig from "./Pages/MeiosDePagamentos/components/Payhow/PayhowConfig"
import MelhorEnvio from "./Pages/MelhorEnvio"
import ConfiguracoesMelhorEnvio from "./Pages/MelhorEnvio/components/ConfiguracoesMelhorEnvio"
import MenuComplements from "./Pages/MenuComplements"
import FormasDeEntrega from "./Pages/MenuComplements/FormasDeEntrega"
import FormasDePagamentos from "./Pages/MenuComplements/FormasDePagamentos"
import MenuMeiosDePagamento from "./Pages/MenuMeiosDePagamento"
import MenuOpcoesDeEntrega from "./Pages/MenuOpcoesDeEntrega"
import MeuLogo from "./Pages/MeuLogo"
import MeuPerfil from "./Pages/MeuPerfil"
import MeusPedidos from "./Pages/MeusPedidos"
import PedidosInfo from "./Pages/MeusPedidos/PedidosInfo"
import MidiasSociais from "./Pages/MidiasSociais"
import ModoDemonstração from "./Pages/ModoDemonstração"
import NotFound from "./Pages/NotFound"
import Notificacoes from "./Pages/Notificacoes"
import NotificacoesExternas from "./Pages/Notificacoes/componentes/NotificacoesExternas"
import OpcoesDeEntrega from "./Pages/OpcoesDeEntrega"
import FreteFixo from "./Pages/OpcoesDeEntrega/FreteFixo"
import FreteGratis from "./Pages/OpcoesDeEntrega/FreteGratis"
import FretePersonalizado from "./Pages/OpcoesDeEntrega/FretePersonalizado"
import IntegracaoCorreios from "./Pages/OpcoesDeEntrega/IntegracaoCorreios"
import RetirarLoja from "./Pages/OpcoesDeEntrega/RetirarLoja"
import SelecionarCidade from "./Pages/OpcoesDeEntrega/componentes/SelecionarCidade"
import SelecionarEstado from "./Pages/OpcoesDeEntrega/componentes/SelecionarEstado"
import OpcoesFrases from "./Pages/OpcoesFrases"
import PagamentoWhatsApp from "./Pages/PagamentoWhatsApp"
import Pedido from "./Pages/Pedido"
import PersonalizarLojinha from "./Pages/PersonalizarLojinha"
import PersonalizarDesign from "./Pages/PersonalizarLojinha/PersonalizarDesign"
import PoliticaPrivacidade from "./Pages/PoliticasPrivacidade"
import SessaoGuiada from "./Pages/PrimeiroAcesso"
import PrimeiroAcesso from "./Pages/PrimeiroAcesso/TelaHome"
import Produto from "./Pages/Produto"
import ProdutoVariacao from "./Pages/Produto/Abas/ProdutosVariacoes/ProdutoVariacao"
import ProdutosDestaque from "./Pages/ProdutosDestaques"
import StoryProducts from "./Pages/ProdutosStories"
import ProdutosVendido from "./Pages/ProdutosVendidos"
import ProfileProgress from "./Pages/ProfileProgress"
import ResetSenha from "./Pages/ResetSenha"
import SelectCategories from "./Pages/SelectCategories"
import SelectVariationType from "./Pages/SelectVariationType"
import Stories from "./Pages/Stories"
import TrocasDevolucoes from "./Pages/TrocasDevolucoes"
// import Cadastro from "./Pages/Cadastro"
import CheckoutMercadoPago from "./Pages/MeiosDePagamentos/components/CheckoutMercadoPago"
// import Planos from "./Pages/Planos"
// import PlanosAlerta from "./Pages/Planos/Alertas"
// import PlanosContratacao from "./Pages/Planos/Contratacao"

export default function Routes() {
  return (
    <Switch>
      {process.env.REACT_APP_MAINTENANCE === "true" && (
        <Route path="*" exact component={InMaintenance} />
      )}
      <Route path="/" exact component={BemVindo} />
      <Route path="/login" exact component={Login} />
      <Route path="/reset" exact component={ResetSenha} />
      <Route path="/progresso-de-configuracoes" exact component={ProfileProgress} />
      <PrivateRoute path="/categorias" exact component={Categoria} />
      <PrivateRoute path="/home" exact component={Home} />
      <PrivateRoute path="/home/left-menu" exact component={LeftMenu} />
      <PrivateRoute path="/nova-categorias" exact component={NovaCategoria} />
      <PrivateRoute path="/gerenciar-categorias" exact component={CategoriasNova} />
      <PrivateRoute path="/produto" exact component={Produto} />
      <PrivateRoute
        path="/selecionar-categorias"
        exact
        component={SelectCategories}
      />
      <PrivateRoute
        path="/selecionar-variacoes"
        exact
        component={SelectVariationType}
      />
      <PrivateRoute path="/produto-variacoes" exact component={ProdutoVariacao} />
      <PrivateRoute path="/meus-produtos" exact component={MeusProdutos} />
      <PrivateRoute path="/meus-pedidos" exact component={MeusPedidos} />
      <PrivateRoute path="/informacao" exact component={PedidosInfo} />
      <PrivateRoute path="/meios-de-pagamento" exact component={MeiosDePagamentos} />
      <PrivateRoute path="/meu-logo" exact component={MeuLogo} />
      <PrivateRoute path="/configuracoes" exact component={Configuracoes} />
      <PrivateRoute path="/opcoes-de-entrega" exact component={OpcoesDeEntrega} />
      <PrivateRoute path="/frete-gratis" exact component={FreteGratis} />
      <PrivateRoute path="/frete-fixo" exact component={FreteFixo} />
      <PrivateRoute
        path="/frete-personalizado"
        exact
        component={FretePersonalizado}
      />
      <PrivateRoute path="/retirar-loja" exact component={RetirarLoja} />
      <PrivateRoute path="/selecionar-estado" exact component={SelecionarEstado} />
      <PrivateRoute path="/selecionar-cidade" exact component={SelecionarCidade} />
      <PrivateRoute path="/checkout-menu" exact component={Checkout} />
      <PrivateRoute path="/informacoes-rodape" exact component={FooterStoreInfo} />
      <PrivateRoute path="/stories" exact component={Stories} />
      <PrivateRoute path="/categorias-stories" exact component={StoryCategories} />
      <PrivateRoute path="/produtos-stories" exact component={StoryProducts} />
      <PrivateRoute path="/habilitar-whatsapp" exact component={AtivarWhatsapp} />
      <PrivateRoute
        path="/personalizar-lojinha/complementos"
        exact
        component={MenuComplements}
      />

      <PrivateRoute path="/institucional" exact component={InstitutionalInfo} />
      <PrivateRoute
        path="/personalizar-lojinha"
        exact
        component={PersonalizarLojinha}
      />
      <PrivateRoute path="/design" exact component={PersonalizarDesign} />
      <PrivateRoute
        path="/informacoes-da-lojinha"
        exact
        component={InformacoesDaLojinha}
      />
      <PrivateRoute path="/marketing" exact component={Marketing} />
      <PrivateRoute
        path="/marketing/facebook-instagram/instagram-shopping"
        exact
        component={InstagramShopping}
      />
      <PrivateRoute
        path="/marketing/facebook-instagram/facebook-pixel"
        exact
        component={FacebookPixel}
      />
      <PrivateRoute
        path="/marketing/google-analytics"
        exact
        component={GoogleAnalytics}
      />
      <PrivateRoute path="/marketing/configSEO" exact component={ConfigSEO} />
      <PrivateRoute
        path="/gerenciamento-de-produtos"
        exact
        component={GerenciaProdutos}
      />
      <PrivateRoute path="/novidades" exact component={ProdutosDestaque} />
      <PrivateRoute
        path="/produtos-mais-vendidos"
        exact
        component={ProdutosVendido}
      />
      {/* <Route path="/planos-alerta" exact component={PlanosAlerta} />
      <Route path="/planos" exact component={Planos} />
      <Route path="/planos-contratacao" exact component={PlanosContratacao} /> */}
      <PrivateRoute path="/meu-perfil" exact component={MeuPerfil} />
      <PrivateRoute path="/estatisticas" exact component={Estatisticas} />
      <PrivateRoute path="/sessao-guiada" exact component={SessaoGuiada} />
      <PrivateRoute
        path="/marketing/falar-com-especialista"
        exact
        component={FalarComEspecialista}
      />
      <PrivateRoute
        path="/acompanhamento-das-vendas"
        exact
        component={AcompanhamentoVendas}
      />
      <PrivateRoute path="/primeiro-acesso" exact component={PrimeiroAcesso} />
      <PrivateRoute
        path="/relatorio-de-visitas"
        exact
        component={RelatorioVisitas}
      />
      <PrivateRoute
        path="/marketing/facebook-instagram"
        exact
        component={FaceInsta}
      />
      <PrivateRoute
        path="/marketing/facebook-instagram/verificacao-dominio"
        exact
        component={VerificacaoDominio}
      />
      <PrivateRoute path="/pedido" exact component={Pedido} />
      <PrivateRoute
        path="/menu-meios-de-pagamento"
        exact
        component={MenuMeiosDePagamento}
      />
      <PrivateRoute
        path="/menu-opcoes-de-entrega"
        exact
        component={MenuOpcoesDeEntrega}
      />
      <PrivateRoute
        path="/integracao-correios"
        exact
        component={IntegracaoCorreios}
      />
      <PrivateRoute
        path="/negociacao-via-whatsapp"
        exact
        component={PagamentoWhatsApp}
      />
      <PrivateRoute path="/pagseguro" exact component={CheckoutPagseguro} />
      <PrivateRoute path="/mercado-pago" exact component={CheckoutMercadoPago} />
      <PrivateRoute path="/melhor-envio" exact component={MelhorEnvio} />
      {/* checkout payhow */}
      <PrivateRoute path="/checkout-payhow" exact component={CheckoutPayhow} />
      <PrivateRoute path="/payhow-config" exact component={PayhowConfig} />
      <PrivateRoute path="/taxas-tarifas" exact component={FeesAndTariffs} />
      <PrivateRoute path="/active-checkout" exact component={ActiveSuperCheckout} />
      <PrivateRoute
        path="/active-payhow-registration"
        exact
        component={ActivePayhowRegistration}
      />
      <PrivateRoute
        path="/service-registration-status"
        exact
        component={ServiceAndRegistrationStatus}
      />
      {/* @end */}
      <PrivateRoute
        path="/configuracao-melhor-envio"
        exact
        component={ConfiguracoesMelhorEnvio}
      />
      <PrivateRoute path="/notificacoes" exact component={Notificacoes} />
      <PrivateRoute
        path="/notificacoes-externas"
        exact
        component={NotificacoesExternas}
      />
      <PrivateRoute path="/banners-principais" exact component={Banner} />
      <PrivateRoute path="/banners-secundarios" exact component={SecondaryBanners} />
      <PrivateRoute path="/adicionar-banners" exact component={AddBanner} />
      <PrivateRoute path="/trocas-devolucoes" exact component={TrocasDevolucoes} />
      <PrivateRoute
        path="/politicas-de-privacidade"
        exact
        component={PoliticaPrivacidade}
      />
      <PrivateRoute path="/frases-destaques" exact component={FrasesDestaques} />
      <PrivateRoute path="/frases-destaques/opcoes" exact component={OpcoesFrases} />
      <PrivateRoute path="/subcategorias" exact component={Subcategorias} />
      <PrivateRoute path="/duvidas-frequentes" exact component={DuvidasFrequentes} />
      <PrivateRoute path="/midias-sociais" exact component={MidiasSociais} />
      <PrivateRoute path="/mais-vendidos" exact component={MaisVendidos} />
      <PrivateRoute path="/atendimento" exact component={Atendimento} />
      <PrivateRoute path="/modo-demonstração" exact component={ModoDemonstração} />
      <PrivateRoute
        path="/formas-de-pagamento"
        exact
        component={FormasDePagamentos}
      />
      <PrivateRoute path="/formas-de-entrega" exact component={FormasDeEntrega} />

      <Route path="/**" exact component={NotFound} />
    </Switch>
  )
}
