import React, { useEffect, useState } from "react"
import { globalStyles } from "../../Componentes/CssGlobal"
import StepProgressBar from "../../Componentes/StepProgressBar"
import lojinhaRequest from "../../Services/api/lojinha"
import EtapaCategorias from "./EtapaCategoria"
import EtapaCores from "./EtapaCores"
import EtapaDescricao from "./EtapaDescricao"
import EtapaLogo from "./EtapaLogo"
import EtapaProduto from "./EtapaProduto"
import EtapaStoriesCategoria from "./EtapaStoriesCategoria"
import EtapaStoriesProduto from "./EtapaStoriesProduto"
import EtapaVisualizar from "./EtapaVisualizar"
import EtapaSubcategorias from "./EtapaSubcategoria"
import useStyles from "./styles"
import { useProduct } from "../../ContextAPI/productContext"
import EtapaBanner from "./EtapaBanner"

export default function SessaoGuiada() {
  const [etapa, setEtapa] = useState(1)
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const [lojinhaShow, setLojinhaShow] = useState({})
  const [categoryId, setCategoryId] = useState(0)
  const { tabSession, setTabSession } = useProduct()
  const handleSetCategoryId = id => {
    setCategoryId(id)
  }
  const handleStep = step => {
    if (tabSession === 5) {
      setEtapa(5)
    } else if (step === "proximo" && etapa < 9) {
      setTimeout(() => {
        setEtapa(oldEtapa => oldEtapa + 1)
      }, 1000)
    } else if (step === "nextStep" && etapa < 9) {
      setEtapa(oldEtapa => oldEtapa + 1)
    } else if (step === "backStep" && etapa > 1) {
      setEtapa(oldEtapa => oldEtapa - 1)
    }
    else if (tabSession === 4) {
      setEtapa(4)
    }
  }
  /* pega as informaçoes da lojinha */
  const getLojinhaPreview = async () => {
    let lojinhaRes = await lojinhaRequest.show()
    if (lojinhaRes.data.error !== true) setLojinhaShow(lojinhaRes.data.data)
  }
  /* carrega as informações da lojinha salva */
  useEffect(() => {
    getLojinhaPreview()
  }, [etapa])

  useEffect(() => {
    if (tabSession == 4) {
      setEtapa(4)
    } else if (tabSession === 5) {
      setEtapa(5)
      setTabSession(1)
    }
  }, [tabSession])

  const handleSetStep = index => {
    setEtapa(index)
  }
  return (
    <div className={classes.EtapaContainer}>
      <div className="page-container">
        <div className="page-align">
          <div className={classes.EtapasTop}>
            <div className={classes.top}>
              <div className={classes.dots}>
                <h2 style={{ paddingRight: "15px" }}>Crie sua loja!</h2>
              </div>
            </div>
            <StepProgressBar
              color="#EA3458"
              steps={[1, 2, 3, 4, 5, 6, 7, 8]}
              current={etapa}
            />
          </div>
          {etapa === 1 && (
            <EtapaLogo
              handleStep={handleStep}
              logo={lojinhaShow.logo ? lojinhaShow.logo : null}
            />
          )}
          {etapa === 2 && <EtapaCores handleStep={handleStep} />}
          {etapa === 3 && (
            <EtapaDescricao
              handleStep={handleStep}
              description={lojinhaShow.description ? lojinhaShow.description : null}
              whats={lojinhaShow.whatsapp ? lojinhaShow.whatsapp : null}
            />
          )}
          {etapa === 4 && (
            <EtapaBanner handleStep={handleStep} from={"sessao-guiada"} />
          )}
          {etapa === 5 && (
            <EtapaCategorias handleStep={handleStep} from={"sessao-guiada"} />
          )}
          {etapa === 6 && <EtapaProduto handleStep={handleStep} />}
          {etapa === 7 && (
            <EtapaStoriesCategoria
              handleStep={handleStep}
              onSetCategoryId={handleSetCategoryId}
              onSetStep={handleSetStep}
            />
          )}
          {etapa === 8 && (
            <EtapaStoriesProduto
              handleStep={handleStep}
              categoryId={categoryId}
              onSetStep={handleSetStep}
            />
          )}
          {etapa === 9 && (
            <EtapaVisualizar
              handleStep={handleStep}
              lojinhaShowInfo={lojinhaShow}
              onSetStep={handleSetStep}
            />
          )}
        </div>
      </div>
    </div>
  )
}
