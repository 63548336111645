import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  CardWrapper: {
    width: "100%",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    margin: "5px 0px",
    padding: "0px 10px",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "5px",
    boxSizing: "border-box",
    border: "2px solid #F3F3F3",
    height: "90px"
  }
}))
