import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import Topo from "../../Componentes/TopCategorias"
import { useProduct } from "../../ContextAPI/productContext"
import { useStyles } from "./style"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

export default function SelectCategories() {
  const classes = useStyles()
  const history = useHistory()
  const {
    currentProduct,
    setCurrentProduct,
    categoriesList,
    categoriesListSelected,
    getCategoriesList
  } = useProduct()
  const [selectedCategories, setSelectedCategories] = useState([])
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = React.useState(true)
  const [subCategories, setSubCategories] = React.useState([])
  const [apenasCategoria, setApenasCategoria] = useState(false)

  useEffect(() => {
    if (currentProduct && currentProduct.categories) {
      transformData()
      setApenasCategoria(false);
    } else {
      setSelectedCategories([])
      setApenasCategoria(true)
      localStorage.removeItem("selectedCategories")
    }
    localStorage.removeItem("selectedCategories")
  }, [currentProduct])

  useEffect(() => {
    getCategoriesList()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const transformData = () => {
    const transformedCategories = []

    categoriesListSelected?.forEach(item => {
      const categoryIndex = transformedCategories.findIndex(
        cat => cat.id === item.category_id
      )

      if (categoryIndex === -1) {
        transformedCategories.push({
          id: item.category_id,
          id_subcategories: item.subcategories_id instanceof Array ? item.subcategories_id.filter(subId => subId !== null) : [item.subcategories_id].filter(subId => subId !== null)
        })
      } else {
        if (item.subcategories_id instanceof Array) {
          transformedCategories[categoryIndex].id_subcategories = [
            ...transformedCategories[categoryIndex].id_subcategories,
            ...item.subcategories_id.filter(subId => subId !== null)
          ]
        } else {
          transformedCategories[categoryIndex].id_subcategories.push(item.subcategories_id)
        }
      }
    })
    setSelectedCategories(transformedCategories)
    return
  }

  useEffect(() => {
    const savedCategories = JSON.parse(localStorage.getItem("selectedCategories"));
    if (savedCategories) {
      setSelectedCategories(savedCategories);
    }
  }, []);


  function handleSave() {
    setCurrentProduct(oldState => {
      return { ...oldState, categories: selectedCategories }
    });
    localStorage.setItem("selectedCategories", JSON.stringify(selectedCategories));
    setShowModalAlert(true);
    setExpanded(false);
    setTimeout(() => history.goBack(), 1500);
  }

  const handleClickSub = subcategory => {
    const categoryIndex = selectedCategories.findIndex(
      cat => cat.id === subcategory.category_id
    )

    if (categoryIndex === -1) {
      setSelectedCategories(prev => [
        ...prev,
        {
          id: subcategory.category_id,
          id_subcategories: [subcategory.id]
        }
      ])
    } else {
      const subcategoryIndex = selectedCategories[
        categoryIndex
      ].id_subcategories.indexOf(subcategory.id)

      if (subcategoryIndex === -1) {
        setSelectedCategories(prev => [
          ...prev.slice(0, categoryIndex),
          {
            ...prev[categoryIndex],
            id_subcategories: [
              ...prev[categoryIndex].id_subcategories,
              subcategory.id
            ]
          },
          ...prev.slice(categoryIndex + 1)
        ])
      } else {
        setSelectedCategories(prev => [
          ...prev.slice(0, categoryIndex),
          {
            ...prev[categoryIndex],
            id_subcategories: [
              ...prev[categoryIndex].id_subcategories.slice(0, subcategoryIndex),
              ...prev[categoryIndex].id_subcategories.slice(subcategoryIndex + 1)
            ]
          },
          ...prev.slice(categoryIndex + 1)
        ])
      }
    }
  }
  const handleChangeApenasCategoria = category => {
    if (apenasCategoria) {
      setSelectedCategories(prev => prev.filter(cat => cat.id !== category.id))
    } else {
      const categoryIndex = selectedCategories.findIndex(
        cat => cat.id === category.id
      )

      if (categoryIndex === -1) {
        setSelectedCategories(prev => [
          ...prev,
          {
            id: category.id,
            id_subcategories: []
          }
        ])
      } else {
        setSelectedCategories(prev => [
          ...prev.slice(0, categoryIndex),
          {
            ...prev[categoryIndex],
            id_subcategories: []
          },
          ...prev.slice(categoryIndex + 1)
        ])
      }
    }
    setApenasCategoria(!apenasCategoria)
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <Topo
            handleClose={() => history.goBack()}
            name="Categorias e subcategorias"
          />
          <div className="infinite-scroll">
            {loading ? (
              <div className={classes.containerLoading}>
                <CircularProgress color="secondary" size="40px" />
              </div>
            )
              : (
                <FormGroup>
                  {categoriesList.length === 0 ? (
                    <p style={{ paddingTop: "20px" }}>
                      Você ainda não possui categorias cadastradas para os seus produtos.
                    </p>
                  ) : (
                    categoriesList.map((category, index) => {
                      const isCategorySelected = selectedCategories.some(
                        cat => cat.id === category.id
                      )

                      return (
                        <div key={category.id}>
                          <Accordion
                            key={category.id}
                            expanded={expanded === index}
                            style={{ backgroundColor: "#F3F3F3", margin: "10px" }}
                            onChange={() =>
                              setExpanded(prev => (prev === index ? false : index))
                            }
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel-${category.id}-content`}
                              id={`panel-${category.id}-header`}
                            >
                              <Typography>{category.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{ display: "flex", flexDirection: "column" }}
                            >
                              <div>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        isCategorySelected &&
                                        selectedCategories.find(cat => cat.id === category.id) &&
                                        selectedCategories.find(cat => cat.id === category.id)?.id_subcategories &&
                                        selectedCategories.find(cat => cat.id === category.id)?.id_subcategories.length === 0 || (selectedCategories.find(cat => cat.id === category.id)?.id_subcategories.length === 1 && selectedCategories.find(cat => cat.id === category.id)?.id_subcategories[0] === null)
                                      }
                                      onChange={() =>
                                        handleChangeApenasCategoria(category)
                                      }
                                      name={`apenasCategoria_${category.id}`}
                                      value={category.id}
                                    />
                                  }
                                  label="Selecionar apenas essa categoria"
                                />
                              </div>
                              {category.subcategories &&
                                category.subcategories.length > 0 &&
                                category.subcategories.map(subcategory => (
                                  <div key={subcategory.id}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={() =>
                                            handleClickSub(subcategory, category)
                                          }
                                          checked={selectedCategories.some(objeto => (objeto.id_subcategories && objeto.id_subcategories.includes(subcategory.id)))}
                                          name={subcategory.id}
                                          value={subcategory.id}
                                          disabled={
                                            isCategorySelected &&
                                            selectedCategories.find(
                                              cat => cat.id === category.id
                                            ) &&
                                            selectedCategories.find(
                                              cat => cat.id === category.id
                                            )?.id_subcategories.length === 0
                                          }
                                        />
                                      }
                                      label={subcategory.sub_name}
                                      style={{
                                        marginBottom: "10px"
                                      }}
                                    />
                                  </div>
                                ))}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )
                    })
                  )}
                </FormGroup>
              )}

          </div>
          <div className="footer-column">
            <hr style={{ width: "100%", margin: "20px 0" }} />
            <Button
              className={classes.btnFooterPink}
              onClick={() => handleSave()}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Salvar e sair
            </Button>
          </div>
        </div>
      </div>
      {/* ALERT COMPONENT */}
      {showModalAlert ? (
        <CustomAlert
          type="success"
          alertText="Categorias salvas com sucesso!"
          show={showModalAlert}
          close={() => setShowModalAlert(false)}
        />
      ) : (
        ""
      )}
    </>
  )
} 
