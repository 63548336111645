import CircularProgress from "@material-ui/core/CircularProgress"
import { useCallback, useState } from "react"
import { useProduct } from "../../../../ContextAPI/productContext"
import CardVendidos from "../CardVendidos"
import defaultImage from "../../../../Assets/img/noProductImg.png"
import maisVendidosRequest from "../../../../Services/api/maisvendidos"

const style = {
  height: "max-content",
  width: "100%",
  margin: "0 0 20px 0"
}

function VendidosContainer({ handleGetSalesList, loading }) {
  const { vendidosList, setVendidosList } = useProduct()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = vendidosList[dragIndex]

      setVendidosList(prevList => {
        const newList = [...prevList]
        newList.splice(dragIndex, 1)

        hoverIndex = Math.min(hoverIndex, newList.length)

        newList.splice(hoverIndex, 0, dragCard)
        return newList
      })
    },
    [vendidosList]
  )

  async function handleDeleteVendidos(id) {
    try {
      setDeleteLoading(true)

      const newState = vendidosList.filter(item =>
        item.id ? item.id !== id : item.product_id !== id
      )

      const productsIds = newState.map(product =>
        product.id ? product.id : product.product_id
      )

      await maisVendidosRequest.put({
        top_body: productsIds
      })

      setVendidosList(newState)

      handleGetSalesList()
    } catch (error) {
      console.error(error)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <div style={style}>
      {loading || deleteLoading? (
        <div
          style={{
            display: "flex",
            height: "100%",
            minHeight: "100%",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        vendidosList?.map((card, index) => (
          <div key={card.id}>
            <CardVendidos
              index={index}
              id={card.id ?? card.product_id}
              text={card.title ?? card.product_title}
              image={card?.product_image ?? defaultImage}
              handleDeleteVendidos={() => handleDeleteVendidos(card.id ?? card.product_id)}
              product_position={card.product_position}
              moveCard={moveCard}
            />
          </div>
        ))
      )}
    </div>
  )
}

export default VendidosContainer
