import Avatar from "@material-ui/core/Avatar"
import { Skeleton } from "@material-ui/lab"
import { useState } from "react"
import { SimpleImg } from "react-simple-img"
import coroinha from "../../Assets/Home/coroinha.png"
import logoCinza from "../../Assets/img/logoCinza.png"
import Topo from "../../Componentes/TopCategorias"
import { useSettings } from "../../ContextAPI/settingsContext"
import api from "../../Services/api"
import ChangePassword from "./components/changePassword"
import Signature from "./components/Signature"
import { useStyles } from "./style"

export default function Home() {
  const classes = useStyles()

  const { lojinhaInfo } = useSettings()

  const [isChangePassword, setIsChangePassword] = useState(false)
  const [logo, setLogo] = useState()
  const [email, setEmail] = useState()
  const [nameLojinha, setNameLojinha] = useState()
  const [loading, setLoading] = useState(true)

  const Lojinha_id = localStorage.getItem("id")

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  api.get(`/lojinha/show/${Lojinha_id}`).then(res => {
    setNameLojinha(res.data.data.name)
    setLogo(res.data.data.sm_url)
    setEmail(res.data.data.email)
    setLoading(false)
  })
  return (
    <>
      {isChangePassword ? (
        <ChangePassword setIsChangePassword={setIsChangePassword} />
      ) : (
        <div className="page-container">
          <div className="page-align" style={{ justifyContent: "flex-start" }}>
            <div>
              <Topo name="Meu Perfil" />
            </div>

            <div className="infinite-scroll">
              {loading ? (
                <section className={classes.headerContainer2}>
                  <Skeleton variant="circle" width={66.8} height={66.8} />

                  <div
                    style={{ width: "60%", marginLeft: "20px" }}
                    className={classes.headerInfos}
                  >
                    <Skeleton width="70%" />
                    <Skeleton width="60%" />
                    <Skeleton width="60%" />
                  </div>
                </section>
              ) : (
                <div className={classes.headerContainer}>
                  {hasPro ? (
                    <>
                      <SimpleImg
                        width={75}
                        height="auto"
                        placeholder={false}
                        className={classes.coroinha}
                        src={coroinha}
                        alt="Borda Coroinha"
                      />
                      <Avatar
                        alt="Remy Sharp"
                        src={logo === "" ? logoCinza : logo}
                        className={classes.large}
                      />
                    </>
                  ) : (
                    <div className={classes.avatarContent}>
                      <Avatar
                        alt="Remy Sharp"
                        src={logo === "" ? logoCinza : logo}
                        className={classes.large}
                      />
                      <span>gratuito</span>
                    </div>
                  )}
                  <div className={classes.textContent}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "21px",
                        margin: "0",
                        marginBottom: "10px"
                      }}
                    >
                      {nameLojinha}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "15px",
                        margin: "0"
                      }}
                    >
                      E-mail de cadastro:
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        lineHeight: "15px",
                        margin: "0"
                      }}
                    >
                      {email}
                    </p>
                  </div>
                </div>
              )}
              <Signature setIsChangePassword={setIsChangePassword} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
