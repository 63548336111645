import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    background: "#ffffff",
    height: "100%",
    minHeight: "100%",
    justifyContent: "space-between",
    flexDirection: "column"
  },

  bodyHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "21px"
  },

  button: {
    color: "#E5E5E5"
  },

  menuIcon: {
    position: "relative"
  },

  notificationsIcon: {
    position: "relative"
  },

  logoBranco: {
    position: "relative"
  },

  lojinhaContent: {
    position: "relative"
  },

  banner: {
    display: "flex",
    height: "60px",
    background: "#051626"
  },

  imgBanner: {
    height: "40px",
    width: "40px",
    padding: "10px 10px "
  },

  titleBanner: {
    margin: "5px 0px",
    color: "#fff"
  },

  subtitleBanner: {
    margin: "5px 0px 0px",
    color: "#fff",
    paddingRight: "20px"
  },

  closeBtn: {
    height: "20px",
    width: "20px",
    color: "#fff",
    padding: "5px"
  },

  ContainerMaintence: {
    marginTop: "40px",
    "& h2": {
      textAlign: "center",
      fontSize: "1rem",
    },
    "& p": {
      fontSize: "0.875rem",
    },
    "& div": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    "& label": {
      color: "#555555",
      fontSize: "1rem",
    }
  }
}))
