import {
  Button,
  Dialog,
  DialogActions,
  InputAdornment,
  TextField
} from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import React, { useEffect, useState } from "react"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"
import CircularProgressWithLabel from "../../../../Componentes/CircularProgressWithLabel"
import Cropper from "../../../../Componentes/Cropper"
import { InputCustom } from "../../../../Componentes/CustomInputs/InputCustom"
import Topo from "../../../../Componentes/TopModalSessaoGuiada"
import { current } from "../../../../Componentes/validates/Validate"
import { useProduct } from "../../../../ContextAPI/productContext"
import conexao from "../../../../Services/api"
import produtosRequest from "../../../../Services/api/produtos"
import { readFile } from "../../../../utils"
import useStyles from "./styles"



export default function CreateProductModal({ isOpen, handleClose, addProduct, handleOpenCategoriesModal }) {
  const classes = useStyles()
  const { currentProduct, getCategoriesList, setCurrentProduct } = useProduct()

  const [loadPage, setLoadPage] = useState(false)
  const [openCrop, setOpenCrop] = useState(false)
  const [srcImg, setSrcImg] = useState(null)
  const [showAlert, setShowAlert] = useState(false)
  const [alertType, setAlertType] = useState("sucess")
  const [alertMessage, setAlertMessage] = useState("")
  const [images, setImages] = useState([])
  const [submit, setSubmit] = useState(false)
  const [newProduct, setNewProduct] = useState({
    title: "",
    description: "",
    price: "",
    quantity: null,
    images: null
  })
  const [emptyPrice, setEmptyPrice] = useState(false)
  const [emptyTitle, setEmptyTitle] = useState(false)
  const [saving, setSaving] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const closeAlerts = () => {
    setShowAlert(false)
  }
  const handleFormChange = e => {
    const { name, value } = e.target
    let newValue = value
    if (name === "title") {
      if (value.length === 0) setEmptyTitle(true)
      else setEmptyTitle(false)
    }
    if (name === "price") {
      if (newValue === 0 || newValue === null) setEmptyPrice(true)
      else setEmptyPrice(false)
      newValue = Number(current(newValue))
    }
    setNewProduct(oldValues => {
      return { ...oldValues, [name]: newValue }
    })
  }



  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let imageDataUrl = await readFile(file)
      setSrcImg(imageDataUrl)
      file = null
      imageDataUrl = null
      setOpenCrop(true)
    }
  }

  const handleCrop = async image => {
    setLoadPage(true)
    setOpenCrop(false)

    const er = /[^a-zA-Zs]/g
    let shop_name = localStorage.getItem("name").replace(er, "").toLocaleLowerCase()

    const payload = {
      path: shop_name,
      photo: srcImg,
      crop: image
    }

    /* cropped upload */
    try {
      const {
        data: { data }
      } = await conexao.post("/image/upload", payload, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total))
          setUploadProgress(progress)
        }
      })

      setSrcImg(data.md_url)
      setImages([
        {
          url: data.url,
          xlg_url: data.xlg_url,
          lg_url: data.lg_url,
          md_url: data.md_url,
          sm_url: data.sm_url,
          url_min: data.sm_url,
          order: null
        }
      ])
    } catch ({ response }) {
      if (response.status === 413) {
        setShowAlert(true)
        setAlertType("error")
        setAlertMessage("Imagem muito grande!")
      }
    } finally {
      setLoadPage(false)
      setUploadProgress(0)
    }
  }

  const addNewProduct = async () => {
    newProduct.images = images.length > 0 ? images : null
    if (newProduct.images === null) {
      setAlertMessage("Adicione uma foto, por favor.")
      setAlertType("error")
      setShowAlert(true)
    } else if (
      (!newProduct.price || Number(newProduct.price) === 0) &&
      !newProduct.title
    ) {
      setEmptyPrice(true)
      setEmptyTitle(true)
      setAlertMessage("Preencha os campos requeridos.")
      setAlertType("error")
      setShowAlert(true)
    } else if (!newProduct.price || Number(newProduct.price) === 0) {
      setEmptyPrice(true)
      setAlertMessage("Preencha o campo preço.")
      setAlertType("error")
      setShowAlert(true)
    } else if (!newProduct.title) {
      setEmptyTitle(true)
      setAlertMessage("Preencha o campo do nome do produto.")
      setAlertType("error")
      setShowAlert(true)
    } else {
      setSaving(true)
      const productRes = await produtosRequest.create({
        ...newProduct,
        quantity: newProduct.quantity ? newProduct.quantity : null,
        variables: [],
        categories: currentProduct?.categories,
        promotional_price: null,
        highlight: 0,
        show_product:1,
        variable_types: JSON.stringify([])
      })
      if (productRes) {
        setSaving(false)
      }
      if (productRes.data.error === false) {
        setAlertType("success")
        setAlertMessage("Produto criado com sucesso.")
        setShowAlert(true)
        setCurrentProduct(oldState => (
          { ...oldState, categories: [] }
        ))
        setTimeout(() => {
          addProduct(newProduct)
          handleClose()
        }, 1000)
      } else {
        setSubmit(true)
        setAlertType("error")
        setAlertMessage("Erro ao criar produto.")
        setShowAlert(true)
      }
    }
  }

  useEffect(() => {
    getCategoriesList()
  }, [])

  return (
    <div
      style={{
        zIndex: "9999",
        display: "flex",
        height: "calc(100vh - calc(100vh - 100%))"
      }}
    >
      <>
        <div>
          {isOpen === true && (
            <>
              <Dialog
                className={classes.newModal}
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogActions style={{ padding: "0px" }}>
                  <div className="page-container">
                    <div className="page-align" style={{ height: "100vh" }}>
                      <div style={{ marginTop: "20px" }}></div>
                      <Topo close={handleClose} name="Primeiro Produto" />
                      <div className="infinite-scroll">
                        <div style={{ marginBottom: "20px", marginTop: "25px" }}>
                          {loadPage ? (
                            <div className={classes.progress}>
                              <CircularProgressWithLabel
                                value={uploadProgress}
                                size={50}
                                color="secondary"
                              />
                            </div>
                          ) : (
                            <>
                              {images.length && images[0] !== null ? (
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    className={classes.previewImg}
                                    src={images[0].url_min}
                                    alt="crop"
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className={classes.defaultImg}></div>
                                </>
                              )}
                            </>
                          )}

                          <div className={classes.positionBtnImg}>
                            <label htmlFor="addFoto">
                              <input
                                accept="image/*"
                                className={classes.input}
                                id="addFoto"
                                type="file"
                                onChange={onFileChange}
                              />
                              <Button
                                className={classes.btnAddImg}
                                variant="contained"
                                component="span"
                                onClick={() => { }}
                              >
                                Escolher imagem
                              </Button>
                            </label>
                          </div>
                        </div>
                        <div className={classes.modalInputs}>
                          <label style={{ marginTop: "1rem" }} htmlFor="city">
                            Nome do produto
                          </label>
                          <TextField
                            name="title"
                            style={{ backgroundColor: "#fff" }}
                            required={emptyTitle}
                            helperText={
                              emptyTitle ? (
                                <p
                                  style={{
                                    color: "red",
                                    margin: 0,
                                    fontSize: "0.8rem"
                                  }}
                                >
                                  campo obrigatório
                                </p>
                              ) : (
                                ""
                              )
                            }
                            value={newProduct.title}
                            type="text"
                            onChange={e => handleFormChange(e)}
                            size="small"
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            inputProps={{ maxLength: 60 }}
                          />
                        </div>
                        <div className={classes.modalInputs}>
                          <label htmlFor="description" style={{ marginTop: "1rem" }}>
                            Descrição
                          </label>
                          <InputCustom
                            style={{
                              paddingTop: 0,
                              backgroundColor: "#fff",
                              fontSize: "1.0em",
                              border: "1px solid #D0D0D0"
                            }}
                            name="description"
                            value={newProduct.description}
                            type="text"
                            callback={e => handleFormChange(e)}
                            submit={submit}
                          />
                        </div>
                        <div className={classes.CreateProductInputGroup}>
                          <div className={classes.modalInputs}>
                            <label style={{ display: "flex" }} htmlFor="price">
                              Preço
                            </label>
                            <TextField
                              style={{
                                paddingTop: 0,
                                backgroundColor: "#fff",
                                fontSize: "0.9rem",
                                fontWeight: "none"
                              }}
                              name="price"
                              required={emptyPrice}
                              helperText={
                                emptyPrice ? (
                                  <p
                                    style={{
                                      color: "red",
                                      margin: 0,
                                      fontSize: "0.8rem"
                                    }}
                                  >
                                    campo obrigatório
                                  </p>
                                ) : (
                                  ""
                                )
                              }
                              value={new Intl.NumberFormat("pt-BR", {
                                minimumFractionDigits: 2
                              }).format(newProduct?.price || 0)}
                              type="tel"
                              onChange={e => handleFormChange(e)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                                maxLength: 7
                              }}
                              color="secondary"
                              variant="outlined"
                            />
                          </div>
                          <div className={classes.modalInputs}>
                            <label style={{ display: "flex" }} htmlFor="quantity">
                              Quantidade
                            </label>
                            <InputCustom
                              style={{
                                paddingTop: 0,
                                backgroundColor: "#fff",
                                fontSize: "0.9rem",
                                border: "1px solid #D0D0D0"
                              }}
                              name="quantity"
                              type="tel"
                              maxLength="9"
                              noLabel
                              value={newProduct.quantity}
                              callback={e => handleFormChange(e)}
                              submit={submit}
                            />
                          </div>

                        </div>
                        <div style={{ width: '100%', display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                          <h3>Categorias</h3>
                          <h3 onClick={() => handleOpenCategoriesModal()} style={{ cursor: "pointer", textDecoration: 'underline' }}>Adicionar categoria</h3>
                        </div>
                        <TextField
                          name="categories"
                          disabled
                          type="text"
                          value={
                            currentProduct &&
                              currentProduct?.categories &&
                              currentProduct?.categories.length
                              ? Object.values(currentProduct.categories).map(cat => cat.name)
                              : "Selecionar categorias"
                          }
                          size="medium"
                          variant="outlined"
                          color="secondary"
                          style={{
                            display: "flex",
                            width: "100%",
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            marginBottom: "20px",
                          }}
                        />

                      </div>

                      {showAlert ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <CustomAlert
                            type={alertType}
                            alertText={alertMessage}
                            show={showAlert}
                            close={() => closeAlerts()}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className={classes.Footer2}>
                        <Button
                          className={classes.btnTamanho}
                          disabled={saving}
                          onClick={() => {
                            addNewProduct(newProduct)
                          }}
                          fullWidth
                          variant="contained"
                          color="secondary"
                        >
                          Salvar Produto
                        </Button>
                      </div>
                    </div>
                  </div>
                </DialogActions>
              </Dialog>
            </>
          )}
          <Cropper
            src={srcImg}
            open={openCrop}
            callback={handleCrop}
            close={() => setOpenCrop(false)}
          />

        </div>


      </>
    </div>
  )
}
