import React from "react"
import useStyles from "./styles"
import { SimpleImg } from "react-simple-img"
import BannerPro from "../../Assets/img/new-banner-seja-pro.png"
import BannerProLite from "../../Assets/img/new-banner-seja-pro-lite.png"
import DesktopBannerPro from "../../Assets/img/new-banner-seja-pro-desktop-2.png"
import DesktopBannerProLite from "../../Assets/img/new-banner-seja-pro-lite-desktop.png"
import { Link } from "react-router-dom"
import { createLeedPro } from "../../utils"
import { useDevice } from "../../ContextAPI/deviceContext"

export default function BannerSejaPro({
  lite = false,
  customBanner,
  bannerId,
  route
}) {
  const classes = useStyles()
  const { checkDevice } = useDevice()

  const hasLeedPro = () => {
    createLeedPro(bannerId)
  }

  BannerPro
  return (
    <>
      {checkDevice().isMobile === true ? (
        <div
          id={bannerId}
          className={classes.BannerSejaProContainer}
          onClick={hasLeedPro}
          aria-hidden="true"
        >
          <Link to="/planos" style={{ margin: "auto" }}>
            {customBanner ? (
              <SimpleImg
                className={classes.BannerSejaProImg}
                src={customBanner}
                alt="banner-seja-pro"
                placeholder={false}
              />
            ) : lite === true ? (
              <SimpleImg
                className={classes.BannerSejaProImgLite}
                src={BannerProLite}
                alt="banner-seja-pro"
                placeholder={false}
              />
            ) : (
              <SimpleImg
                className={
                  route === "home"
                    ? classes.BannerSejaProImgHome
                    : classes.BannerSejaProImg
                }
                src={DesktopBannerPro}
                alt="banner-seja-pro"
                placeholder={false}
              />
            )}
          </Link>
        </div>
      ) : (
        <div
          id={bannerId}
          className={classes.BannerSejaProContainer}
          onClick={hasLeedPro}
          aria-hidden="true"
        >
          <Link to="/planos" style={{ margin: "auto" }}>
            {customBanner ? (
              <SimpleImg
                className={classes.DesktopBannerSejaProImg}
                src={customBanner}
                alt="banner-seja-pro"
                placeholder={false}
              />
            ) : lite === true ? (
              <SimpleImg
                className={classes.DesktopBannerSejaProImgLite}
                src={DesktopBannerProLite}
                alt="banner-seja-pro"
                placeholder={false}
              />
            ) : (
              <SimpleImg
                className={
                  route === "home"
                    ? classes.DesktopBannerSejaProImgHome
                    : classes.DesktopBannerSejaProImg
                }
                src={DesktopBannerPro}
                alt="banner-seja-pro"
                placeholder={false}
              />
            )}
          </Link>
        </div>
      )}
    </>
  )
}
