import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import moment from "moment"
import { useEffect, useState } from "react"
import { SimpleImg } from "react-simple-img"
import cadeado from "../../../../Assets/svg/cadeado.svg"
import { useSettings } from "../../../../ContextAPI/settingsContext"
import api from "../../../../Services/api"
import { useStyles } from "./style"

export default function Signature({ setIsChangePassword }) {
  const classes = useStyles()
  const [signatureInfo, setSignatureInfo] = useState([])

  const { lojinhaInfo } = useSettings()

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  useEffect(() => getProInfo(), [])

  async function getProInfo() {
    const resInfo = await api.get(`pro/getbyuser/${localStorage.getItem("user_id")}`)
    setSignatureInfo(resInfo.data.data)
  }

  return (
    <>
      <section className={classes.signatureContainer}>
        <div className={classes.signatureHeader}>
          <p
            style={{
              margin: "0",
              marginBottom: "14px",
              fontSize: "16px",
              fontWeight: "600",
              color: "#EA3458"
            }}
          >
            Minha assinatura
          </p>
          {hasPro ? (
            <>
              <div className={classes.signatureTexts}>
                <div>
                  <p>Data de contratação: </p>
                  <p>Data de expiração do plano: </p>
                  <p>Valor do plano: </p>
                </div>
                <div className={classes.groupData}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-end"
                    }}
                  >
                    <span>
                      {moment(signatureInfo.created_at).format("DD/MM/YYYY")}
                    </span>
                    <span>{moment(signatureInfo.expires).format("DD/MM/YYYY")}</span>

                    <span>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      }).format(signatureInfo.value / 100)}
                    </span>
                    {/* <a
                      aria-hidden="true"
                      className={classes.pagesContent}
                      href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/9"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline", marginTop: "5px" }}
                    >
                      <span>Fale conosco</span>
                    </a> */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={classes.signatureTexts}>
              <div>
                <p>Data de contratação: </p>
                <p>Data de expiração do plano: </p>
                <p>Valor do plano: </p>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", width: "40%" }}
              >
                <span>{moment(signatureInfo.created_at).format("DD/MM/YYYY")}</span>
                <span>Sem Expiração</span>
                <span>Plano gratuito</span>
                {/* <a
                  aria-hidden="true"
                  className={classes.pagesContent}
                  href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/9"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "underline", marginTop: "5px" }}
                >
                  <span>Fale conosco</span>
                </a> */}
              </div>
            </div>
          )}
        </div>

        <div
          aria-hidden="true"
          onClick={() => setIsChangePassword(true)}
          className={classes.changePassword}
        >
          <div>
            <SimpleImg
              width={20}
              height="auto"
              placeholder={false}
              src={cadeado}
              alt="imc cadeado"
            />
            <p>Alterar senha de acesso</p>
          </div>
          <ArrowForwardIosIcon fontSize="small" color="disabled" />
        </div>
      </section>
    </>
  )
}
