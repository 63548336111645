import React, { useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useStyles } from "./style"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import { SimpleImg } from "react-simple-img"
import ModalFoto from "../../ModalFoto"
import star_icon from "../../../../../../Assets/svg/star_icon.svg"

function CardFotos({ id, index, image, handleDelete, priority, moveCard }) {
  const ref = useRef(null)
  const classes = useStyles()

  // OPEN FULL IMAGE
  const [openModalPhoto, setOpenModalPhoto] = useState(false)
  const [fullImage, setFullImage] = useState({ url: "", index: "" })

  /* OPEN FULL SIZE PHOTO */
  const openPhoto = (url, position) => {
    setFullImage({ url, index })
    setOpenModalPhoto(true)
  }

  /* CLOSE FULL SIZE PHOTO */
  function closePhoto() {
    setOpenModalPhoto(false)
  }

  const [{ handlerId }, drop] = useDrop({
    accept: "Card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag] = useDrag({
    type: "Card",
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const opacity = isDragging ? 0.5 : 1

  drag(drop(ref))

  return (
    <>
      <div
        ref={ref}
        className={classes.CardWrapper}
        style={{
          opacity,
          transform: isDragging ? "rotate(2deg)" : "rotate(0deg)",
          margin: isDragging ? "15px 0px" : "5px 10px",
          boxShadow: isDragging
            ? "1px 1px 10px rgba(0, 0, 0, 0.5)"
            : "0px 3px 10px rgba(0, 0, 0, 0.1)"
        }}
        data-handler-id={handlerId}
      >

        {index === 0 && (
          <img
            src={star_icon}
            alt="Star icon"
            className={classes.star}
          />
        )}

        <img
          width="auto"
          height="auto"
          alt="img do produto"
          aria-hidden="true"
          className={classes.imgProduto}
          src={image}
          onClick={() => {
            openPhoto(image, index)
          }}
        />
      </div>

      <ModalFoto
        open={openModalPhoto}
        close={closePhoto}
        index={fullImage.index}
        src={fullImage.url}
        excluir={handleDelete}
      />
    </>
  )
}

export default CardFotos
