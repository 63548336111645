import {
  FormControl,
  InputAdornment,
  NativeSelect,
  TextField
} from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import InputBase from "@material-ui/core/InputBase"
import { withStyles } from "@material-ui/core/styles"
import Switch from "@material-ui/core/Switch"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { button } from "../../../Componentes/Inputs/TextFild"
import Topo from "../../../Componentes/TopCategorias"
import { current } from "../../../Componentes/validates/Validate"
import api from "../../../Services/api"
import { useStyles } from "./style"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(4)
    }
  },
  input: {
    width: "100%",
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid  #EA3458",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    color: "#EA3458",
    fontWeight: "500",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#EA3458",
      boxShadow: "0 0 0 0.1rem #EA3458cc"
    }
  }
}))(InputBase)

export default function FretePersonalizado() {
  const classes = useStyles()

  const [enableCustomDelivery, setEnableCustomDelivery] = useState(false)
  const [enableCustomFree, setEnableCustomFree] = useState(false)
  const [firstTime, setFirstTime] = useState(false)
  const [alertType, setAlertType] = useState("success")
  const [showAlert, setShowAlert] = useState(false)
  const [alertMsg, setAlertMsg] = useState("Salvo com sucesso")
  const [loading, setLoading] = useState(true)
  const [shippingId, setShippingId] = useState(null)
  const shopId = localStorage.getItem("id")
  const [customFormData, setCustomFormData] = useState(null)
  const history = useHistory()

  const handleBackward = () => {
    setTimeout(() => {
      history.goBack()
    }, 2500)
  }

  const getInformations = async () => {
    api.get(`/lojinha/shipping/get/${shopId}/custom`).then(res => {
      const currentInformations = res.data.data
      if (currentInformations === null) {
        setFirstTime(true)
        setLoading(false)
      } else {
        if (currentInformations.shipping_data.value === 0) {
          setEnableCustomFree(true)
        }
        let new_time_type
        if (currentInformations.shipping_data.average_time_type === "days") {
          new_time_type = "DIAS"
        } else if (currentInformations.shipping_data.average_time_type === "hours") {
          new_time_type = "HORAS"
        } else {
          new_time_type = "MINUTOS"
        }

        const nameDataGet = currentInformations.shipping_data.shipping_name || ""
        const priceDataGet = currentInformations.shipping_data.value || 0
        const time_deliveryDataGet =
          currentInformations.shipping_data.average_time || ""
        const time_typeDataGet = new_time_type || ""

        setCustomFormData({
          name: nameDataGet,
          price: priceDataGet,
          time_delivery: time_deliveryDataGet,
          time_type: time_typeDataGet
        })

        setShippingId(currentInformations.id)

        if (priceDataGet > 0) {
          setEnableCustomFree(false)
        }

        setEnableCustomDelivery(currentInformations.shipping_active)
      }

      setLoading(false)
    })
  }

  async function dataSave() {
    let new_time_type

    if (customFormData.time_type === "DIAS") {
      new_time_type = "days"
    } else if (customFormData.time_type === "HORAS") {
      new_time_type = "hours"
    } else {
      new_time_type = "minutes"
    }

    const bodyCreate = {
      lojinha_id: shopId,
      shipping_type: "custom_shipping",
      shipping_active: enableCustomDelivery ? 1 : 0,
      shipping_data: {
        shipping_name: customFormData.name,
        value: customFormData.price === 0 ? 0 : customFormData.price,
        average_time: customFormData.time_delivery,
        average_time_type: new_time_type
      }
    }

    const bodyUpdate = {
      lojinha_id: shopId,
      shipping_active: enableCustomDelivery ? 1 : 0,
      shipping_type: "custom_shipping",
      shipping_data: {
        shipping_name: customFormData.name,
        value: customFormData.price === 0 ? 0 : customFormData.price,
        average_time: customFormData.time_delivery,
        average_time_type: new_time_type
      }
    }

    if (firstTime === true) {
      let resCreate = await api.post(`/lojinha/shipping/create`, bodyCreate)
      if (resCreate.data.error === false) {
        setShowAlert(true)
        setAlertType("success")
        setAlertMsg("Salvo com sucesso!")
        handleBackward()
      } else {
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Erro ao salvar informações")
      }
    } else {
      let res = await api.put(`/lojinha/shipping/update/${shippingId}`, bodyUpdate)
      if (res.data.error === false) {
        setShowAlert(true)
        setAlertType("success")
        setAlertMsg("Salvo com sucesso!")
        handleBackward()
      } else if (res.data.message === "Existe apenas um checkout ativo!") {
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Deixe pelo menos 1 frete ativado!")
      } else {
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Erro ao salvar informações")
      }
    }
  }

  useEffect(() => {
    getInformations()
  }, [])

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div>
            <Topo name="Frete Personalizado" />
            <CustomAlert
              type={alertType}
              alertText={alertMsg}
              show={showAlert}
              close={() => setShowAlert(false)}
            />
          </div>
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <div className={classes.toggledTitle}>
                  <h2>Habilitar frete personalizado</h2>
                  <Switch
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onClick={() => {
                      setEnableCustomDelivery(!enableCustomDelivery)
                    }}
                    checked={enableCustomDelivery}
                  />
                </div>

                {enableCustomDelivery ? (
                  <>
                    <div className={classes.divbox}>
                      <div style={{ marginBottom: "20px" }}>
                        <h4
                          style={{
                            margin: "10px 0",
                            fontWeight: "500",
                            color: "#555555"
                          }}
                        >
                          Nome personalizado dessa opção de entrega:
                        </h4>
                        <TextField
                          name="title"
                          onChange={e => {
                            setCustomFormData({
                              ...customFormData,
                              name: e.target.value
                            })
                          }}
                          size="small"
                          variant="outlined"
                          type="text"
                          color="secondary"
                          value={customFormData?.name || " "}
                          fullWidth
                          style={{ backgroundColor: "#fff" }}
                                                    inputProps={{ maxLength: 30 }}

                        />
                      </div>
                    </div>

                    <div>
                      <div className={classes.divbox}>
                        <div className={classes.switch}>
                          <h2>Esse frete será gratuito?</h2>
                          <Switch
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            onClick={() => {
                              setEnableCustomFree(!enableCustomFree)
                            }}
                            checked={enableCustomFree}
                          />
                        </div>

                        {!enableCustomFree ? (
                          <>
                            <h4
                              style={{
                                margin: "10px 0",
                                fontWeight: "500",
                                color: "#555555"
                              }}
                            >
                              Digite o valor do frete
                            </h4>
                            <TextField
                              name="price"
                              onChange={e => {
                                setCustomFormData({
                                  ...customFormData,
                                  price: current(e.target.value.substring(0, 20))
                                })
                              }}
                              size="small"
                              variant="outlined"
                              type="tel"
                              color="secondary"
                              value={
                                customFormData !== null &&
                                customFormData?.price &&
                                customFormData?.price !== "0,00"
                                  ? new Intl.NumberFormat("pt-BR", {
                                      minimumFractionDigits: 2
                                    }).format(customFormData.price)
                                  : "0,00"
                              }
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                              style={{ backgroundColor: "#fff" }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <h4
                          style={{
                            margin: "30px 0 10px",
                            fontWeight: "500",
                            color: "#555555"
                          }}
                        >
                          Tempo estimado para entrega:
                        </h4>
                        <div className={classes.switch}>
                          <TextField
                            name="shipping"
                            size="small"
                            color="secondary"
                            variant="outlined"
                            type="tel"
                            onChange={e => {
                              setCustomFormData({
                                ...customFormData,
                                time_delivery: e.target.value.replace(/\D/g, '')
                              })
                            }}
                            value={customFormData?.time_delivery || ""}
                            style={{ backgroundColor: "#fff", width: "65%" }}
                          />
                          <div className={classes.dropdown}>
                            <FormControl>
                              <NativeSelect
                                id="demo-customized-select-native"
                                value={customFormData?.time_type || ""}
                                onChange={e => {
                                  setCustomFormData({
                                    ...customFormData,
                                    time_type: e.target.value
                                  })
                                }}
                                input={<BootstrapInput />}
                              >
                                <option>DIAS</option>
                                <option>HORAS</option>
                                <option>MINUTOS</option>
                              </NativeSelect>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%", borderColor: "#E4E4E4", opacity: 0.3 }} />

            {button({
              name: "Salvar Alterações",
              funcao: dataSave
            })}
          </div>
        </div>
      </div>
    </>
  )
}
