import { Button } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import bemvindoBackground from "../../Assets/img/bem-vindo-background.png"
import bemvindoImage from "../../Assets/img/bem-vindo-image.png"
import logoBranco from "../../Assets/img/logobranco.png"
import Password from "../../Componentes/Inputs/Password"
import { button, buttonSecundary, texto } from "../../Componentes/Inputs/TextFild"
import conexao from "../../Services/api"
import { useStyles } from "./style"
import { SimpleImg } from "react-simple-img"

import { isLogin } from "../../utils"

export default function BemVindo() {
  const classes = useStyles()
  const history = useHistory()

  const state = {
    user: { value: "", error: false },
    password: { value: "", error: false }
  }

  const [form, setForm] = useState(state)
  const [next, setnext] = useState(true)

  useEffect(() => {
    if (isLogin()) history.push("/home")
  })

  function pegadados(e) {
    const { name, value } = e
    if (value === "") {
      setForm({ ...form, [name]: { value, error: true } })
    } else {
      setForm({ ...form, [name]: { value, error: false } })
    }
  }

  function Enviar() {
    if (form.user.value !== "" && form.password.value !== "") {
      conexao
        .post("/login", {
          email: form.user.value,
          password: form.password.value
        })
        .then(res => {
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("token_cortado", res.data.token.substr(7))
          localStorage.setItem("tokenCreateAt", res.data.data.tokenCreateAt)
          localStorage.setItem("tokenExpMinutes", res.data.data.tokenExpMinutes)
          history.push("/home")
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      if (form.user.value === "") {
        setForm({ ...form, user: { value: "", error: true } })
      }
      if (form.password.value === "") {
        setForm({ ...form, password: { value: "", error: true } })
      }
    }
  }

  const version = process.env.REACT_APP_VERSION

  return (
    <>
      {next ? (
        <div
          className="page-align"
          style={{
            background: `url(${bemvindoBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: "0px 12px 10px 5px #d9d9d9"
          }}
        >
          <div
            style={{
              width: "100%",
              height: " 100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end"
            }}
          >
            <div className={classes.positionRight}>
              <p aria-hidden="true" className={classes.version}>
                v. {version}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                flex: "1"
              }}
            >
              <SimpleImg
                src={logoBranco}
                alt="logo-branco"
                width={250}
                placeholder={false}
                height={147}
                className="bemVindo"
              />
              <SimpleImg
                src={bemvindoImage}
                alt="bem-vindo-imagem"
                width={245}
                placeholder={false}
                height="auto"
                imgStyle={{ maxHeight: "214", margin: "25px 0" }}
                className="bemVindo"
              />
            </div>
            <div>
              <div className={classes.widthBTN}>
                <Button
                  onClick={() => history.push("/login")}
                  fullWidth
                  variant="outlined"
                  disableRipple
                  className={classes.buttonAcessa}
                >
                  Acessar minha conta
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="page-container">
            <div className={classes.height100vh}>
              <div>
                <div className={classes.img}>
                  <SimpleImg
                    src={logoBranco}
                    style={{ width: "180px" }}
                    alt="..."
                    placeholder={false}
                  />
                </div>

                <div className={classes.textfield}>
                  {texto({
                    name: "user",
                    title: "E-mail",
                    css: "",
                    pegadados,
                    error: form.user.error
                  })}
                  <Password pegadados={pegadados} label="Senha" />
                </div>

                <div
                  aria-hidden
                  className={classes.positionLeft}
                  onClick={() => {
                    history.push("/reset")
                  }}
                >
                  <p className={classes.esqueciPassword}>Esqueci minha senha</p>
                </div>
                <div className={classes.widthBTN}>
                  {button({ name: "Entrar", funcao: Enviar })}
                  {buttonSecundary({ name: "Voltar" })}
                </div>
              </div>

              <div className={classes.widthTextCriar}>
                <p className={classes.textCriar}>
                  Não tem uma conta?{" "}
                  <a
                    style={{
                      color: "#EA3458",
                      cursor: "pointer",
                      fontWeight: "bold"
                    }}
                    href="/cadastro"
                  >
                    Clique aqui para criar
                  </a>
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
