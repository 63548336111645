import { CircularProgress } from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import React from "react"
import { SimpleImg } from "react-simple-img"
import { useProduct } from "../../../../../ContextAPI/productContext"
import { useStyles } from "./style"

export default function CardVariation({ variations, handleEdit, loading }) {
  const classes = useStyles()
  const { currentProduct } = useProduct()

  function handleEditCallback(id) {
    handleEdit(id)
  }

  return (
    <>
      {loading === true && (
        <div
          style={{
            backgroundColor: " #f9f9f9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "600px",
            margin: "50px 0px"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      )}
      {loading === false &&
        variations.map((item, index) => {
          return (
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => handleEditCallback(item.id)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "90px",
                  margin: "15px 0",
                  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)"
                }}
              >
                <div className={classes.variationCardContainer}>
                  <div className={classes.variationImage}>
                    <SimpleImg
                      alt="imagem variacao"
                      height="80px"
                      width="80px"
                      placeholder={false}
                      style={{ borderRadius: "5px" }}
                      src={
                        item.images.length > 0
                          ? item.images[0].sm_url
                          : currentProduct && currentProduct.images[0].sm_url
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "5px" }}>
                    <div className={classes.variationTagsList}>
                      {item.types.map(types => {
                        return (
                          <div className={classes.variationTags}>
                            <p className={classes.variationTagsTitle}>{types.tag}</p>
                          </div>
                        )
                      })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                        marginTop: "5px"
                      }}
                    >
                      <div className={classes.variationDescription}>
                        <div
                          style={{
                            marginTop: "1rem"
                          }}
                        >
                          <p className={classes.variationPrice}>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                              minimumFractionDigits: 2
                            }).format(
                              item.price > 0 ? item.price : currentProduct.price
                            )}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end"
                          }}
                        >
                          {item.quantity >= 0 ? (
                            <p className={classes.variationStorage}>
                              Qtd. Estoque:
                              {item.quantity}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ArrowForwardIosIcon
                  style={{ color: "#EA3458", fontSize: "2rem" }}
                />
              </div>
            </button>
          )
        })}
    </>
  )
}
