import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import "elt-react-credit-cards/es/styles-compiled.css"
import React, { useState } from "react"
import { BrowserRouter } from "react-router-dom"
import Routes from "./routes"
import "./style.css"
/* imports */

/* contexts */
import { Container } from "@material-ui/core"
import { CookiesProvider } from "react-cookie"
import MobileDeviceText from "./Componentes/MobileDeviceText"
import ContextProvider from "./ContextAPI"
import { DeviceProvider } from "./ContextAPI/deviceContext"
import { ProductProvider } from "./ContextAPI/productContext"
import { SettingsProvider } from "./ContextAPI/settingsContext"

const theme = createTheme({
  palette: {
    primary: {
      main: "#051626"
    },
    secondary: {
      main: "#EA3458"
    }
  }
})

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <DeviceProvider>
        <CookiesProvider>
          <SettingsProvider>
            <ProductProvider>
              <ContextProvider>
                <div
                  style={{
                    backgroundColor: "#F9F9F9",
                    height: "100%"
                  }}
                >
                  <MobileDeviceText />
                  <Container
                    maxWidth="sm"
                    style={{
                      padding: "0px",
                      height: "100%"
                    }}
                  >
                    <BrowserRouter>
                      <Routes />
                    </BrowserRouter>
                  </Container>
                </div>
              </ContextProvider>
            </ProductProvider>
          </SettingsProvider>
        </CookiesProvider>
      </DeviceProvider>
    </ThemeProvider>
  )
}
