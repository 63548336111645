import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
    Section: {
        position: "relative",
        margin: "0 auto",
        width: "100%",
        "& main": {
            margin: "2rem 0",
        }
    },
    StoryContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "11px",
        margin: "auto"
    },

    AddStory: {
        "& svg": {
            color: "#EA3458",
            fontSize: "3rem",
        },
        background: "none",
        border: "none",
        cursor: "pointer",
        position: "absolute",
        top: "50px",
        right: "-10px"


    },
    EmptyStories: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        opacity: "0.7",
        marginTop: "11rem",
        "& p": {
            fontSize: "0.9rem",
            color: "#8b8b8b",
            textAlign: "center",
        },
        "& svg ": {
            color: "#8b8b8b",
            fontSize: "3rem",

        }
    }
}))