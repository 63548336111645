import CircularProgress from "@material-ui/core/CircularProgress"
import update from "immutability-helper"
import { useCallback, useEffect, useState } from "react"
import { useProduct } from "../../../../ContextAPI/productContext"
import api from "../../../../Services/api"
import categoriasRequest from "../../../../Services/api/categorias"
import CardDestaques from "../CardVendidos"
import defaultImage from "../../../../Assets/img/noProductImg.png"

const style = {
  height: "max-content",
  width: "100%",
  margin: "0 0 20px 0"
}

function DestaquesContainer() {
  const { productsTopSaleList, setProductsTopSaleList } = useProduct()
  const [loading, setLoading] = useState(false)

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = productsTopSaleList[dragIndex]
      setProductsTopSaleList(
        update(productsTopSaleList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      )
    },
    [productsTopSaleList]
  )

  function handleDelete(id) {
    setProductsTopSaleList(oldState => oldState.filter(item => item.id !== id))
  }

  const renderCard = (card, index) => {
    return (
      <CardDestaques
        key={card.id}
        index={index}
        id={card.id}
        text={card.title}
        image={card.images.length > 0 ? card.images[0].sm_url : defaultImage}
        handleDelete={handleDelete}
        priority_top_sale={card.priority_top_sale}
        moveCard={moveCard}
      />
    )
  }

  async function handleGetTopSaleList() {
    setLoading(true)
    const resProduct = await api.get(
      `/product/gettopsales/${localStorage.getItem("id")}/10`
    )
    if (resProduct) {
      setProductsTopSaleList(resProduct.data.data)
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetTopSaleList()
  }, [])

  return (
    <div style={style}>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            minHeight: "100%",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        productsTopSaleList.map((card, i) => renderCard(card, i))
      )}
    </div>
  )
}

export default DestaquesContainer
