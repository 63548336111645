import React, { useEffect, useState } from "react"
import { globalStyles } from "../../Componentes/CssGlobal"
import TopCategorias from "./Componentes/TopCategorias"
import { useHistory } from "react-router-dom"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useStyles } from "./style"
import { useSettings } from "../../ContextAPI/settingsContext"

export default function Categoria(props) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const history = useHistory()
  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <TopCategorias name="Categorias" />
          </div>
          <div className="infinite-scroll">
            <spam className={classes.boxConteudo}>
              <div
                aria-hidden="true"
                style={{cursor: "pointer"}}
                onClick={() => {
                  history.push("/gerenciar-categorias")
                }}
              >
                <spam className={classes.fonteTitulo}>Gerenciar categorias</spam>
                <div className={classes.btnalinhamento}>
                  <div>
                    <spam className={classes.fonteSubtitulo}>
                      Nessa área você pode gerenciar suas categorias!
                    </spam>
                  </div>
                  <div>
                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                </div>
                <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
              </div>
            </spam>
          </div>
        </div>
      </div>
    </>
  )
}
