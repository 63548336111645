import { Button, CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { SimpleImg } from "react-simple-img"
import LogoCinza from "../../Assets/img/logoCinza.png"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import CircularProgressWithLabel from "../../Componentes/CircularProgressWithLabel"
import Cropper from "../../Componentes/Cropper"
import Topo from "../../Componentes/TopCategorias"
import { useSettings } from "../../ContextAPI/settingsContext"
import conexao from "../../Services/api"
import { readFile } from "../../utils"
import makeStyles from "./style"

export default function MenuLogo() {
  const history = useHistory()
  const classes = makeStyles()
  const { getLojinhaInfo } = useSettings()
  const [ImageSrc, setImageSrc] = useState({})
  const [preview, setPreview] = useState("")
  const [openCrop, setOpenCrop] = useState(false)
  const [url, setUrl] = useState()
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertText, setAlertText] = useState("")
  const [loadPage, setLoadPage] = useState(true)
  const [saving, setSaving] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  useEffect(() => {
    getData()
  }, [])

  const handleCroppedImg = img => {
    imageUpload(ImageSrc, img)
    setOpenCrop(false)
    setImageSrc(null)
  }

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let imageDataUrl = await readFile(file)
      setImageSrc(imageDataUrl)
      file = null
      imageDataUrl = null
      setOpenCrop(true)
    }
  }

  async function getData() {
    const id = localStorage.getItem("id")

    try {
      const { data } = await conexao.get(`/lojinha/show/${id}`, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total))
          setUploadProgress(progress)
        }
      })

      setPreview(data.data.logo)
    } catch (err) {
      setAlertType("error")
      setAlertText("Algo deu errado!")
      setShowAlertError(true)
    } finally {
      setLoadPage(false)
    }
  }

  async function imageUpload(src, min) {
    setLoadPage(true)

    const er = /[^a-zA-Zs]/g
    let shop_name = localStorage.getItem("name").replace(er, "").toLocaleLowerCase()

    const payload = {
      path: shop_name,
      photo: src,
      crop: min
    }

    try {
      const {
        data: { data }
      } = await conexao.post("/image/upload", payload, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total))
          setUploadProgress(progress)
        }
      })

      setUrl({
        logo: data.url,
        xlg_url: data.xlg_url,
        lg_url: data.lg_url,
        md_url: data.md_url,
        sm_url: data.sm_url,
        url_min: data.sm_url,
        order: null
      })
      setPreview(data.md_url)
    } catch ({ response }) {
      if (response.status === 413) {
        setShowAlertError(true)
        setAlertType("error")
        setAlertText("Imagem muito grande!")
      }
    } finally {
      setLoadPage(false)
      setUploadProgress(0)
    }
  }

  async function saveAvatar() {
    setSaving(true)
    const id = localStorage.getItem("id")

    try {
      await conexao.put(`/lojinha/update/${id}`, url)

      setAlertType("success")
      setAlertText("Logo salvo com sucesso!")
      setShowAlertOk(true)
      getLojinhaInfo()

      setTimeout(() => {
        history.goBack()
      }, 1000)
    } catch (err) {
      setAlertType("error")
      setAlertText("Algo deu errado!")
      setShowAlertError(true)
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      {openCrop === false ? (
        <div className="page-container">
          <div className="page-align">
            <div>
              <Topo name="Logo" />
            </div>
            <div className={classes.logo}>
              {loadPage ? (
                <div className={classes.progress}>
                  <CircularProgressWithLabel
                    value={uploadProgress}
                    size={50}
                    color="secondary"
                  />
                </div>
              ) : (
                <div className={classes.positionImg}>
                  {preview ? (
                    <SimpleImg
                      width="auto"
                      height="auto"
                      placeholder={false}
                      alt="logo da sua loja"
                      className={classes.previewImg}
                      src={preview}
                    />
                  ) : (
                    <div className={classes.creatImg}>
                      <div className={classes.positionLogo}>
                        <SimpleImg
                          width="auto"
                          height="auto"
                          placeholder={false}
                          alt="logoDefault"
                          className={classes.defaultLogo}
                          src={LogoCinza}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className={classes.positionBtnAddLogo}>
                <label htmlFor="addFoto">
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="addFoto"
                    type="file"
                    onChange={onFileChange}
                  />

                  <Button
                    className={classes.btnAddLogo}
                    variant="contained"
                    component="span"
                    onClick={() => {}}
                  >
                    Escolher imagem
                  </Button>
                </label>
              </div>

              <div className={classes.positionTextDescription}>
                <span className={classes.textDescription}>
                  Resolução recomendável 500px por 500px
                  <br />
                  Tamanho máx.: 3 mb
                </span>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {/* alertas */}
                <CustomAlert
                  type={alertType}
                  alertText={alertText}
                  show={showAlertOk || showAlertError}
                  close={() => closeAlerts()}
                />
              </div>
            </div>
            <div className="footer-column">
              <hr style={{ width: "100%" }} />
              <Button
                className={classes.btnAddLogo}
                variant="contained"
                disabled={saving}
                onClick={() => {
                  saveAvatar()
                }}
              >
                Salvar alterações
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Cropper
          src={ImageSrc}
          open={openCrop}
          close={() => setOpenCrop(false)}
          callback={handleCroppedImg}
        />
      )}
    </>
  )
}
