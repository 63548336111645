import React, { useState } from "react"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useHistory } from "react-router-dom"
import TopoCloseIcon from "./components/TopoCloseIcon"
import LeftMenuHeader from "./components/LeftMenuHeader"
import logoutIcon from "../../../../Assets/Home/logoutIcon.png"
import { globalStyles } from "../../../../Componentes/CssGlobal"
import { useStyles } from "./style"
import { logout } from "../../../../utils"
import { SimpleImg } from "react-simple-img"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"

export default function LeftMenu() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const history = useHistory()
  const [alertType, setAlertType] = useState("")
  const [showAlert, setShowAlert] = useState(false)

  const closeAlerts = () => {
    setShowAlert(false)
  }

  function alertLink() {
    setAlertType("success")
    setShowAlert(true)
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div>
            <TopoCloseIcon />
            <LeftMenuHeader alertLink={alertLink} />
            <hr
              style={{
                width: "100%",
                borderColor: "#E4E4E4",
                opacity: 0.25,
                marginTop: "21px",
                marginBottom: "22px"
              }}
            />
          </div>

          <div className="infinite-scroll">
            <div
              aria-hidden="true"
              onClick={() => history.push("/gerenciamento-de-produtos")}
              className={classes.pagesContent}
              style={{ cursor: "pointer" }}
            >
              <span>Gerenciar produtos</span>
              <ArrowForwardIosIcon fontSize="small" color="disabled" />
            </div>
            <div
              aria-hidden="true"
              onClick={() => history.push("/categorias")}
              className={classes.pagesContent}
              style={{ cursor: "pointer" }}
            >
              <span>Gerenciar categorias</span>
              <ArrowForwardIosIcon fontSize="small" color="disabled" />
            </div>

            <div
              aria-hidden="true"
              onClick={() => history.push("/meus-pedidos")}
              className={classes.pagesContent}
              style={{ cursor: "pointer" }}
            >
              <span>Gerenciar pedidos</span>
              <ArrowForwardIosIcon fontSize="small" color="disabled" />
            </div>

            <div
              aria-hidden="true"
              onClick={() => history.push("/configuracoes")}
              className={classes.pagesContent}
              style={{ cursor: "pointer" }}
            >
              <span>Configurações da loja</span>
              <ArrowForwardIosIcon fontSize="small" color="disabled" />
            </div>

            <div
              aria-hidden="true"
              onClick={() => history.push("/marketing")}
              style={{ cursor: "pointer" }}
              className={classes.pagesContent}
            >
              <span>Configurações de marketing</span>
              <ArrowForwardIosIcon fontSize="small" color="disabled" />
            </div>

            <div
              aria-hidden="true"
              onClick={() => history.push("/estatisticas")}
              className={classes.pagesContent}
              style={{ cursor: "pointer" }}
            >
              <span>Estatísticas da loja</span>
              <ArrowForwardIosIcon fontSize="small" color="disabled" />
            </div>

            <div
              aria-hidden="true"
              onClick={() => history.push("/meu-perfil")}
              className={classes.pagesContent}
              style={{ cursor: "pointer" }}
            >
              <span>Meu perfil</span>
              <ArrowForwardIosIcon fontSize="small" color="disabled" />
            </div>

            <a
              aria-hidden="true"
              className={classes.pagesContent}
              href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/9"
              target="_blank"
              rel="noreferrer"
            >
              <span>Suporte técnico</span>
              <ArrowForwardIosIcon fontSize="small" color="disabled" />
            </a>

            <button
              onClick={() => logout()}
              style={{
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                padding: 0
              }}
              className={classes.pagesContent}
            >
              <span>Sair</span>
              <SimpleImg
                placeholder={false}
                width={15}
                height="auto"
                style={{ marginLeft: "8px" }}
                src={logoutIcon}
                alt="img-logout"
              />
            </button>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <CustomAlert
                type={alertType}
                alertText="Link copiado com sucesso"
                show={showAlert}
                close={() => closeAlerts()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
