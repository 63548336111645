import React from "react"
import { useStyles } from "./style"
import CloseIcon from "@material-ui/icons/Close"

export default function TopModal({ handleClose, name }) {
  const classes = useStyles()

  return (
    <div className={classes.top}>
      <div className={classes.space} />
      <h2>{name}</h2>
      <CloseIcon className={classes.btnClose} onClick={() => handleClose()} />
    </div>
  )
}
