import React from "react"
import Topo from "../../Componentes/TopCategorias"
import { useSettings } from "../../ContextAPI/settingsContext"
import ProgressCard from "./ProgressCard"
import ProgressModal from "./ProgressModal"

export default function ProfileProgress() {
  const { lojinhaInfo, profileProgress, progressCount } = useSettings()
  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  return (
    <>
      {progressCount < 5 ? (
        <div className="page-container">
          <div className="page-align" style={{ justifyContent: "flex-start" }}>
            <Topo name="Progresso de configurações" />
            <div className="infinite-scroll">
              <ProgressCard
                checked={profileProgress.logo}
                text="Adicionar uma logo"
                link="/meu-logo"
              />
              <ProgressCard
                checked={profileProgress.info}
                text="Informações da loja"
                link="/informacoes-da-lojinha"
              />
              <ProgressCard
                checked={profileProgress.payment}
                text="Meios de Pagamento"
                link="/menu-meios-de-pagamento"
              />
              <ProgressCard
                checked={profileProgress.shipping}
                text="Opções de Entrega"
                link="/menu-opcoes-de-entrega"
              />
              <ProgressCard
                checked={profileProgress.product}
                text="Criar um produto"
                link="/meus-produtos"
              />

            </div>
          </div>
        </div>
      ) : (
        <ProgressModal />
      )}
    </>
  )
}
