import { Button, InputAdornment, InputBase } from "@material-ui/core"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import Topo from "../../Componentes/TopCategorias"
import { useProduct } from "../../ContextAPI/productContext"
import api from "../../Services/api"
import DestaquesContainer from "./DragAndDropDestaques/DestaquesContainer"
import { useStyles } from "./style"
import SearchIcon from "@material-ui/icons/Search"
import { Cancel } from "@material-ui/icons"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { object } from "zod"
import newsRequest from "../../Services/api/news"
import { useEffect } from "react"

export default function Novidades() {
  const errorStyle = {
    color: "red",
    fontSize: "0.9rem"
  }

  const classes = useStyles()
  const { productsHighlightList, setProductsHighlightList, setQuery } = useProduct()
  const [alertType, setAlertType] = useState("")
  const [searchText, setSearchText] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [clearSearch, setClearSearch] = useState(false)
  const [options, setOptions] = useState([])
  const [newProducts, setNewProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorProductExist, setErrorProductExist] = useState(false)

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  async function getOptions() {
    try {
      const data = await newsRequest.getOptions()
      setOptions(data.data.data)
    } catch (error) {}
  }

  useEffect(() => {
    getOptions()
  }, [])

  async function handleGetHighlightList() {
    setLoading(true)
    try {
      const resProduct = await api.get(`/v2/lojinha/products/news/list`)
      if (resProduct) {
        setProductsHighlightList(resProduct.data.data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetHighlightList()
  }, [])

  async function handleSaveDestaquesList() {
    const productsIds = productsHighlightList.map(product =>
      product.id ? product.id : product.product_id
    )

    try {
      const { data } = await newsRequest.putNewOption({
        news_body: productsIds
      })
      if (data) {
        setAlertType("success")
        setShowAlertOk(true)
        handleGetHighlightList()
      } else {
        setAlertType("error")
        setShowAlertError(true)
      }
    } catch (error) {}
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <Topo name="Novidades" />
          <div className="infinite-scroll">
            <div style={{ marginBottom: "1rem" }}>
              <Autocomplete
                id="tags-standard"
                onChange={(event, newValue) => {
                  const isProductExist = productsHighlightList.some(
                    existingProduct =>
                      existingProduct.product_title === newValue?.product_title
                  )

                  if (isProductExist) {
                    setErrorProductExist(true)
                    return
                  }

                  if (newValue && !isProductExist) {
                    setProductsHighlightList(prevList => [...prevList, newValue])
                    setErrorProductExist(false)
                    return
                  }
                }}
                options={options}
                getOptionLabel={option => option.product_title}
                renderInput={params => (
                  <TextField {...params} variant="standard" placeholder="Produtos" />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={props.product_image}
                        alt={props.product_title}
                        style={{ width: 50, marginRight: 10 }}
                      />
                      {props.product_title}
                    </div>
                  </li>
                )}
              />
              {errorProductExist && (
                <p style={errorStyle}>Esse produto já está selecionado.</p>
              )}
            </div>
            <div className="infinite-scroll">
              <DestaquesContainer
                handleGetHighlightList={handleGetHighlightList}
                loading={loading}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <CustomAlert
                type={alertType}
                alertText={
                  alertType === "success"
                    ? "Dados salvos com sucesso!"
                    : "Algo deu errado!"
                }
                show={showAlertOk || showAlertError}
                close={() => closeAlerts()}
              />
            </div>
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%" }} />
            <Button
              onClick={() => handleSaveDestaquesList()}
              style={{
                color: "#fff",
                minWidth: "100%",
                height: "50px",
                boxShadow:
                  "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                marginTop: "5px",
                marginBottom: "15px",
                textTransform: "none",
                backgroundColor: "#EA3458"
              }}
              type="submit"
              variant="contained"
            >
              Salvar alterações
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
